import React, { useState } from "react";
import { HosptialLeftSideNav, LeftSide } from ".";
import { ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.min.css";
import TopNavTS from "./TopNavTS";

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [navToggle, setNavToggle] = useState(false);
  return (
    <div className="d-flex" style={{ overflow: 'hidden' }}>
      <div className="min-vh-100" style={{ position: 'relative', top: 1, left: 1, zIndex: 1, overflow: 'hidden' }}>
        <LeftSide nav={navToggle} />
      </div>
      <main className="w-100">
        <TopNavTS toggleSideMenu={() => setNavToggle(!navToggle)} />
        <div className="px-4">
          {/* <ToastContainer limit={1} /> */}
          {children}
        </div>
      </main>
      {/* <RightSideBar /> */}
    </div>
  );
};

export default Layout;