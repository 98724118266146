import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../common";
import { IApiResponse, IPaginate } from "../../../interfaces/common";
import {
  IBloodBankPayload,
  IBloodRequestFormValuesPatModule,
  IEditBloodRequestPatModule,
  IFormValuesForBloodAcquisition,
} from "../../../interfaces/patientBloodManagement";
import { toast } from "react-toastify";
import patientBloodManagementHasPriceReducer, {
  setPatientBloodManagementHasPrice,
} from "../../hospitalRedux/patientBloodManagementPriceNotSetSlice";

export interface IApiResponseForBloodBank<T> {
  success: boolean;
  payload: T;
  message?: string;
}

interface BloodBankError {
  statusCode: number;
  success: boolean;
  message: string;
  error: any; // Use "any" for now as the inner error structure is unknown
}

export const patientBloodManagementApi = createApi({
  reducerPath: "patient-blood-management",
  baseQuery: baseQuery,
  tagTypes: ["patient-blood-management"],
  refetchOnFocus: true,
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    getBloodBank: builder.query<IApiResponse<IBloodBankPayload>, void>({
      query: () => ({
        url: `patient-blood-management/blood-bank`,
        method: "GET",
      }),
      providesTags: [{ type: "patient-blood-management", id: "LIST" }],
    }),
    getBloodBankWithPath: builder.query<
      IApiResponse<IBloodBankPayload>,
      { pathname: string }
    >({
      query: () => ({
        url: `patient-blood-management/blood-bank`,
        method: "GET",
      }),
      async onQueryStarted(arg, api) {
        try {
          await api.queryFulfilled;
        } catch (error: any) {
          error?.error?.data?.message ===
            "Error: Blood Price Has Not Been Set" &&
            arg.pathname === "/hospital/blood-management" &&
            api.dispatch(setPatientBloodManagementHasPrice(true));
          // toast.error(error?.error?.data?.message);
        }
      },
      providesTags: [{ type: "patient-blood-management", id: "LIST" }],
    }),
    getBloodGroupAvailableQuantity: builder.query<
      IApiResponse<IBloodBankPayload>,
      Partial<{ bloodGroup: string }>
    >({
      query: (body) => ({
        url: `/patient-blood-management/available-blood?bloodGroup=${body.bloodGroup}`,
        method: "GET",
      }),
      providesTags: [{ type: "patient-blood-management", id: "LIST" }],
    }),
    addBloodRequest: builder.mutation<
      IBloodBankPayload,
      Partial<IBloodRequestFormValuesPatModule>
    >({
      query: (body) => ({
        url: `/patient-blood-management/add-blood-request`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["patient-blood-management"],
    }),
    updateBloodRequest: builder.mutation<
      IBloodBankPayload,
      Partial<IEditBloodRequestPatModule>
    >({
      query(data) {
        return {
          url: `/patient-blood-management/edit-blood-request`,
          method: "PATCH",
          body: data,
        };
      },
      invalidatesTags: ["patient-blood-management"],
    }),
    getBloodRequestForPatient: builder.query<
      IApiResponse<IPaginate<any[]>>,
      Partial<{
        patientId: string;
        pagination: { page: number; limit: number };
      }>
    >({
      query: (prop) =>
        `/patient-blood-management/blood-requests/patient?patientId=${prop.patientId}&page=${prop?.pagination?.page}&limit=${prop?.pagination?.limit}`,
      providesTags: [{ type: "patient-blood-management", id: "LIST" }],
    }),
    addBloodAcquisition: builder.mutation<
      IBloodBankPayload,
      Partial<IFormValuesForBloodAcquisition>
    >({
      query: (body) => ({
        url: `/patient-blood-management/create-blood-acquisition`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["patient-blood-management"],
    }),
  }),
});

export const {
  useGetBloodBankQuery,
  useGetBloodBankWithPathQuery,
  useGetBloodGroupAvailableQuantityQuery,
  useAddBloodRequestMutation,
  useUpdateBloodRequestMutation,
  useGetBloodRequestForPatientQuery,
  useAddBloodAcquisitionMutation,
} = patientBloodManagementApi;
