import { createApi } from "@reduxjs/toolkit/query/react";
import { number, string } from "yup/lib/locale";
import { IApiResponse, IPaginate } from "../../interfaces/common";
import { IPreExistingCondition } from "../../interfaces/preExistingConditions";
import { baseQuery } from "./common";

export const preExistingConditionsApi = createApi({
  reducerPath: "preexistingconditions",
  baseQuery: baseQuery,
  tagTypes: [
    "PreExistingConditions",
    "PreExistingCondition",
    "PreExistingConditionsByDate",
    "PreExistingConditionsByHospital",
    "UniqueHospitals",
  ],
  refetchOnFocus: true,
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    getPreExistingConditionsForPatient: builder.query<
      IApiResponse<IPaginate<IPreExistingCondition[]>>,
      { id: string; pagination: { page: number; limit: number } }
    >({
      query: ({ id, pagination: { page = 1, limit = 10 } }) => `/preExistingConditions/patient/${id}?page=${page}&limit=${limit}`,
      providesTags: (result, error, arg) => [{ type: "PreExistingConditions", id: arg.id }],
    }),
    searchPreExistingConditionsForPatient: builder.query<
      IApiResponse<IPaginate<IPreExistingCondition[]>>,
      { id: string; searchText: string; pagination: { page: number; limit: number } }
    >({
      query: ({ id, searchText, pagination: { page = 1, limit = 10 } }) =>
        `/preExistingConditions/patient/${id}/name/${searchText}?page=${page}&limit=${limit}`,
      providesTags: (result, error, arg) => [{ type: "PreExistingConditionsByDate", id: `${arg.id}-${arg.searchText}` }],
    }),
    getPreExistingConditionsByHospitalForPatient: builder.query<
      IApiResponse<IPaginate<IPreExistingCondition[]>>,
      { id: string; hospitalId: string; pagination: { page: number; limit: number } }
    >({
      query: ({ id, hospitalId, pagination: { page = 1, limit = 10 } }) =>
        `/preExistingConditions/patient/${id}/hospital/${hospitalId}?page=${page}&limit=${limit}`,
      providesTags: (result, error, arg) => [{ type: "PreExistingConditionsByHospital", id: `${arg.id}-${arg.hospitalId}` }],
    }),
    getUniqueHospitalsForPatient: builder.query<IApiResponse<{ name: string; id: string }[]>, { id: string }>({
      query: ({ id }) => `/preExistingConditions/patient/${id}/getUniqueHospitals`,
      providesTags: (result, error, arg) => [{ type: "UniqueHospitals", id: `${arg.id}` }],
    }),
    addPreExistingConditions: builder.mutation<IPreExistingCondition, Partial<IPreExistingCondition>>({
      query: (body) => ({
        url: `preExistingConditions`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["PreExistingConditions", "PreExistingCondition"],
    }),
    updatePreExistingConditions: builder.mutation<IPreExistingCondition, Partial<IPreExistingCondition>>({
      query(data) {
        return {
          url: `preExistingConditions/${data.id}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: (result, error, arg) => [{ type: "PreExistingCondition", id: arg.id }, "PreExistingConditions"],
    }),
  }),
});

export const {
  useGetPreExistingConditionsForPatientQuery,
  useAddPreExistingConditionsMutation,
  useUpdatePreExistingConditionsMutation,
  useSearchPreExistingConditionsForPatientQuery,
  useGetUniqueHospitalsForPatientQuery,
  usePrefetch,
} = preExistingConditionsApi;
