import { createApi } from "@reduxjs/toolkit/query/react";
import {
  ILabRequest,
  ILabResponse,
  IRadRequest,
  ISpecimen,
  ISpecimenRes,
} from "../../../interfaces/lab";
import { IApiResponse, IPaginate } from "../../../interfaces/common";
import { baseQuery } from "../common";
import { string } from "yup";

export const radRequestApi = createApi({
  reducerPath: "radRequest",
  baseQuery: baseQuery,
  tagTypes: ["RadRequest"],
  refetchOnFocus: false,
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    getRadRequestForPatient: builder.query<
      IApiResponse<IPaginate<ILabResponse[]>>,
      { pagination: { page: number; limit: number }; id: string }
    >({
      query: ({ pagination: { page = 1, limit = 10 }, id }) =>
        `/radiology-request/by-patient-for-hospital/${id}?page=${page}&limit=${limit}`,
      providesTags: [{ type: "RadRequest", id: "LIST" }],
    }),
    getRadRequest: builder.query<
      IApiResponse<IPaginate<IRadRequest[]>>,
      { pagination: { page: number; limit: number } }
    >({
      query: ({ pagination: { page = 1, limit = 10 } }) =>
        `/radiology-request?status=specimen-due&page=${page}&limit=${limit}`,
      providesTags: [{ type: "RadRequest", id: "LIST" }],
    }),
    getRadRequestInprogress: builder.query<
      IApiResponse<IPaginate<IRadRequest[]>>,
      { pagination: { page: number; limit: number } }
    >({
      query: ({ pagination: { page = 1, limit = 10 } }) =>
        `/radiology-request?status=specimen-taken&page=${page}&limit=${limit}`,
      providesTags: [{ type: "RadRequest", id: "LIST" }],
    }),
    getRadRequestCancelled: builder.query<
      IApiResponse<IPaginate<IRadRequest[]>>,
      { pagination: { page: number; limit: number } }
    >({
      query: ({ pagination: { page = 1, limit = 10 } }) =>
        `/radiology-request?status=cancelled&page=${page}&limit=${limit}`,
      providesTags: [{ type: "RadRequest", id: "LIST" }],
    }),
    getRadRequestCompleted: builder.query<
      IApiResponse<IPaginate<IRadRequest[]>>,
      { pagination: { page: number; limit: number } }
    >({
      query: ({ pagination: { page = 1, limit = 10 } }) =>
        `/radiology-request?status=result-released&page=${page}&limit=${limit}`,
      providesTags: [{ type: "RadRequest", id: "LIST" }],
    }),
    getRadRequestForAnId: builder.query<IApiResponse<ILabResponse>, string>({
      query: (id) => `/radiology-request/${id}`,
      providesTags: (result, error, arg) => [{ type: "RadRequest", id: arg }],
    }),
    addRadRequestInternal: builder.mutation<IRadRequest, Partial<IRadRequest>>({
      query: (body) => ({
        url: `/radiology-request/create-internal`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["RadRequest"],
    }),
    addRadRequestInternalMultiple: builder.mutation<
      IRadRequest,
      Partial<IRadRequest>
    >({
      query: (body) => ({
        url: `/radiology-request/create-multiple-internal`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["RadRequest"],
    }),
    addRadRequestExternal: builder.mutation<IRadRequest, Partial<IRadRequest>>({
      query: (body) => ({
        url: `/radiology-request/create-external`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["RadRequest"],
    }),
    addSpecimmen: builder.mutation<ISpecimen, Partial<ISpecimenRes>>({
      query: (body) => ({
        url: `/lab-request/create-specimen`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["RadRequest"],
    }),
    updateRadTest: builder.mutation<IRadRequest, Partial<IRadRequest>>({
      query(data) {
        return {
          url: `/radiology-request/${data.id}/${data.type}`,
          method: "PATCH",
          body: data,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "RadRequest", id: arg.id },
        "RadRequest",
      ],
    }),
    getSearchTest: builder.query<
      IApiResponse<IPaginate<IRadRequest[]>>,
      {
        searchString?: string;
        status?: string;
        dueDateRangeStart?;
        dueDateRangeEnd?;
        requestDateStart?;
        requestDateEnd?;
        pagination: { page?: number; limit?: number };
      }
    >({
      query: ({
        searchString,
        status,
        dueDateRangeStart,
        dueDateRangeEnd,
        requestDateStart,
        requestDateEnd,
        pagination: { page = 1, limit = 10 },
      }) =>
        `/radiology-request/filter?page=${page}&limit=${limit}&status=${status}${
          searchString && `&searchString=${searchString}`
        }${dueDateRangeStart && `&dueDateRangeStart=${dueDateRangeStart}`}${
          dueDateRangeEnd && `&dueDateRangeEnd=${dueDateRangeEnd}`
        }${requestDateStart && `&requestDateStart=${requestDateStart}`}${
          requestDateEnd && `&requestDateEnd=${requestDateEnd}`
        }`,
      providesTags: [{ type: "RadRequest", id: "LIST" }],
    }),

    filterPatientRadTest: builder.query<
      IApiResponse<IPaginate<ILabResponse[]>>,
      {
        pagination: { page: number; limit: number };
        searchTerm?: string;
        status?: string;
        patientId?: string;
        filterDate?: string;
      }
    >({
      query: ({
        pagination: { page = 1, limit = 10 },
        searchTerm,
        status,
        patientId,
        filterDate,
      }) =>
        `radiology-request/test-pbm/filter/${patientId}?page=${page}&limit=${limit}${
          status !== undefined && status === "in_progress"
            ? `&status=specimen_taken&status=result_entered&filterOperator=and`
            : status !== undefined && status !== "in_progress"
              ? `&status=${status}`
              : ``
        }${searchTerm !== undefined ? `&selectedTest=${searchTerm}` : ``}${
          filterDate !== undefined ? `&date=${filterDate}` : ``
        }
      `,
      providesTags: [{ type: "RadRequest", id: "LIST" }],
    }),
  }),
});

export const {
  useGetRadRequestForPatientQuery,
  useGetRadRequestQuery,
  useGetRadRequestInprogressQuery,
  useGetRadRequestCancelledQuery,
  useGetRadRequestCompletedQuery,
  useGetRadRequestForAnIdQuery,
  useAddSpecimmenMutation,
  useAddRadRequestInternalMutation,
  useAddRadRequestExternalMutation,
  useUpdateRadTestMutation,
  useAddRadRequestInternalMultipleMutation,
  useGetSearchTestQuery,
  useFilterPatientRadTestQuery,
} = radRequestApi;
