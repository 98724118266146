import { createApi } from "@reduxjs/toolkit/query/react";
import { ILabCategory } from "../../../interfaces/lab";
import {
  IApiResponse,
  IPaginate,
  NoPaginate,
} from "../../../interfaces/common";
import { baseQuery } from "../common";

export const radiologyCategoryApi = createApi({
  reducerPath: "radiologyCategory",
  baseQuery: baseQuery,
  tagTypes: ["RadiologyCategories", "RadiologyCategory"],
  refetchOnFocus: true,
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    getRadiologyCat: builder.query<
      IApiResponse<IPaginate<ILabCategory[]>>,
      { pagination: { page: number; limit: number } }
    >({
      query: ({ pagination: { page = 1, limit = 10 } }) =>
        `/radiology/test/category?page=${page}&limit=${limit}`,
      providesTags: [{ type: "RadiologyCategories", id: "LIST" }],
    }),
    getAllRadiologyCat: builder.query<
      IApiResponse<NoPaginate<ILabCategory[]>>,
      void
    >({
      query: () => `/radiology/test/category?limit=All`,
      providesTags: [{ type: "RadiologyCategories", id: "LIST" }],
    }),
    getRadiologyCatForAnId: builder.query<IApiResponse<ILabCategory>, string>({
      query: (id) => `/radiology/test/category/${id}`,
      providesTags: (result, error, arg) => [
        { type: "RadiologyCategory", id: arg },
      ],
    }),
    addRadiologyCat: builder.mutation<ILabCategory, Partial<ILabCategory>>({
      query: (body) => ({
        url: `/radiology/test/category`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["RadiologyCategories", "RadiologyCategory"],
    }),
    updateRadiologyCat: builder.mutation<ILabCategory, Partial<ILabCategory>>({
      query(data) {
        return {
          url: `/radiology/test/category/${data.id}`,
          method: "PATCH",
          body: data,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "RadiologyCategory", id: arg.id },
        "RadiologyCategories",
      ],
    }),
    deleteRadiologyCat: builder.mutation<ILabCategory, Partial<ILabCategory>>({
      query: ({ id }) => ({
        url: `/radiology/test/category/${id}`,
        method: "DELETE",
        body: id,
      }),
      invalidatesTags: ["RadiologyCategories", "RadiologyCategory"],
    }),
  }),
});

export const {
  useGetRadiologyCatQuery,
  useGetAllRadiologyCatQuery,
  useAddRadiologyCatMutation,
  useUpdateRadiologyCatMutation,
  useDeleteRadiologyCatMutation,
} = radiologyCategoryApi;
