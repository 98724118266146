import React, { lazy, Suspense, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ErrorBoundary } from "./components";
import { useHistory } from "react-router-dom";
import "./App.css";
import { Loading } from "./components/Loading";
import IdleTimer from "./components/elements/utils/idleTimer";
import { GlobalDebug } from "./utils/disableConsoleLog";
const Hospital = lazy(() => import("./pages/HospitalAdmin"));
const Patient = lazy(() => import("./pages/PatientDashboard"));
const SuperAdmin = lazy(() => import("./pages/SuperAdmin"));
const Sample = lazy(() => import("./pages/sample"));

function App() {
  useEffect(() => {
    (process.env.NODE_ENV === "production" ||
      process.env.REACT_APP_ENV === "STAGING") &&
      GlobalDebug(false, true);
  }, []);
  return (
    <>
      <ErrorBoundary>
        <Suspense fallback={<Loading />}>
          <Router>
            <Switch>
              <Route path="*" component={Hospital} />

              {/* <Route exact path="/hospital" component={Hospital} /> */}
              {/* <Route path="/patient" component={Patient} />
	<Route path="/admin" component={SuperAdmin} /> */}
              {/* <Route exact path="/hospital" component={Hospital} /> */}
            </Switch>
          </Router>
        </Suspense>
      </ErrorBoundary>
    </>
  );
}

export default App;
