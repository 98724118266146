import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import counterReducer from "../features/counter/counterSlice";
import UserReducer from "./hospitalRedux/userSlice";
import authReducer from "./hospitalRedux/userSlice";
import patientRegReducer from "./hospitalRedux/PatientSlice";
import inventoryReducer from "./hospitalRedux/inventory";
import { setupListeners } from "@reduxjs/toolkit/query";
import { prescriptionsApi } from "./services/prescriptions";
import { labTestApi } from "./services/labModule/testList";
import { testCategoryApi } from "./services/labModule/testCategory";
import { patientsApi } from "./services/patients";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import {
  MiddlewareAPI,
  isRejectedWithValue,
  Middleware,
} from "@reduxjs/toolkit";
import { nextOfKinApi } from "./services/nextofkin";
import { authApi } from "./services/auth";
import { preExistingConditionsApi } from "./services/preexistingconditions";
import { vitalsApi } from "./services/vitals";
import { hmoApi } from "./services/hmo";
import { userApi } from "./services/user";
import { countriesApi } from "./services/countries";
import { specializationApi } from "./services/specialization";
import { rolesApi } from "./services/roles-permissions";
import { brandsApi } from "./services/brand";
import { modulesApi } from "./services/module";
import { medicationTypeApi } from "./services/medicationType";
import { currencyApi } from "./services/currency";
import { vendorApi } from "./services/vendor";
import { productsApi } from "./services/products";
import { medicationRequestApi } from "./services/medication/medicationRequest";
import { labRequestApi } from "./services/labModule/labRequest";
import { radiologyCategoryApi } from "./services/radiology/radiology-category";
import { radiologyTestApi } from "./services/radiology/radiology-test";
import { radRequestApi } from "./services/radiology/radRequest";
import { RolesCheckApi } from "./services/rolesAndPermissions/roles";
import { departmentApi } from "./services/department";
import { appointmentTypeApi } from "./services/appointmentType";
import { appointmentApi } from "./services/appointment/appointment";
import { serviceTypeApi } from "./services/billing/billableItems/serviceType";
import { itemApi } from "./services/billing/billableItems/item";
import { invoiceApi } from "./services/billing/invoice/invoice";
import { bloodmanagementApi } from "./services/bloodmanagement";
import { patientBloodManagementApi } from "./services/patientBloodManagement/patientBloodManagement";
import { patientBillingApi } from "./services/patient-refactoredService/billing/patientBilling";
import { notificationApi } from "./services/notification";
import patientBloodManagementHasPriceReducer from "../app/hospitalRedux/patientBloodManagementPriceNotSetSlice";
import { admissionApi } from "./services/admission/admission";
import { categoryApi } from "./services/cartegory";
import hospitalActivationTimerReducer from "../app/hospitalRedux/hospitalActivationSlice";
import { reportApi } from "./services/report/report";

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      if (
        action?.payload?.status == 401 ||
        action?.payload?.data?.statusCode == 401 ||
        action?.meta?.baseQueryMeta?.response?.status == 401
      ) {
        localStorage.clear();
        if (!window.location?.pathname?.includes("/login")) {
          window.location.href = "/hospital/login";
        }
      }
    }
    return next(action);
  };

const customMiddleWares = [
  prescriptionsApi.middleware,
  patientsApi.middleware,
  nextOfKinApi.middleware,
  preExistingConditionsApi.middleware,
  vitalsApi.middleware,
  hmoApi.middleware,
  userApi.middleware,
  specializationApi.middleware,
  rolesApi.middleware,
  modulesApi.middleware,
  countriesApi.middleware,
  brandsApi.middleware,
  medicationTypeApi.middleware,
  categoryApi.middleware,
  currencyApi.middleware,
  vendorApi.middleware,
  productsApi.middleware,
  labTestApi.middleware,
  radRequestApi.middleware,
  testCategoryApi.middleware,
  labRequestApi.middleware,
  medicationRequestApi.middleware,
  radiologyCategoryApi.middleware,
  radiologyTestApi.middleware,
  RolesCheckApi.middleware,
  departmentApi.middleware,
  appointmentTypeApi.middleware,
  appointmentApi.middleware,
  serviceTypeApi.middleware,
  itemApi.middleware,
  invoiceApi.middleware,
  bloodmanagementApi.middleware,
  patientBloodManagementApi.middleware,
  patientBillingApi.middleware,
  notificationApi.middleware,
  admissionApi.middleware,
  reportApi.middleware,
  rtkQueryErrorLogger,
];

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    auth: authReducer,
    patientReg: patientRegReducer,
    inventory: inventoryReducer,
    patientBloodManagementHasPrice: patientBloodManagementHasPriceReducer,
    hospitalActivationTimer: hospitalActivationTimerReducer,
    [prescriptionsApi.reducerPath]: prescriptionsApi.reducer,
    [preExistingConditionsApi.reducerPath]: preExistingConditionsApi.reducer,
    [patientsApi.reducerPath]: patientsApi.reducer,
    [nextOfKinApi.reducerPath]: nextOfKinApi.reducer,
    [vitalsApi.reducerPath]: vitalsApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [hmoApi.reducerPath]: hmoApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [specializationApi.reducerPath]: specializationApi.reducer,
    [rolesApi.reducerPath]: rolesApi.reducer,
    [modulesApi.reducerPath]: modulesApi.reducer,
    [countriesApi.reducerPath]: countriesApi.reducer,
    [brandsApi.reducerPath]: brandsApi.reducer,
    [medicationTypeApi.reducerPath]: medicationTypeApi.reducer,
    [categoryApi.reducerPath]: categoryApi.reducer,
    [currencyApi.reducerPath]: currencyApi.reducer,
    [vendorApi.reducerPath]: vendorApi.reducer,
    [productsApi.reducerPath]: productsApi.reducer,
    [labTestApi.reducerPath]: labTestApi.reducer,
    [testCategoryApi.reducerPath]: testCategoryApi.reducer,
    [labRequestApi.reducerPath]: labRequestApi.reducer,
    [radRequestApi.reducerPath]: radRequestApi.reducer,
    [medicationRequestApi.reducerPath]: medicationRequestApi.reducer,
    [radiologyCategoryApi.reducerPath]: radiologyCategoryApi.reducer,
    [radiologyTestApi.reducerPath]: radiologyTestApi.reducer,
    [RolesCheckApi.reducerPath]: RolesCheckApi.reducer,
    [departmentApi.reducerPath]: departmentApi.reducer,
    [appointmentTypeApi.reducerPath]: appointmentTypeApi.reducer,
    [appointmentApi.reducerPath]: appointmentApi.reducer,
    [serviceTypeApi.reducerPath]: serviceTypeApi.reducer,
    [itemApi.reducerPath]: itemApi.reducer,
    [invoiceApi.reducerPath]: invoiceApi.reducer,
    [bloodmanagementApi.reducerPath]: bloodmanagementApi.reducer,
    [patientBloodManagementApi.reducerPath]: patientBloodManagementApi.reducer,
    [patientBillingApi.reducerPath]: patientBillingApi.reducer,
    [notificationApi.reducerPath]: notificationApi.reducer,
    [admissionApi.reducerPath]: admissionApi.reducer,
    [reportApi.reducerPath]: reportApi.reducer,
    // user:UserReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(customMiddleWares),
  devTools: process.env.NODE_ENV !== "development" ? false : true,
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
// export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
