import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

interface IRes<T> {
  error: boolean;
  msg: string;
  data: T;
}

interface CountiresInfo {
  name: string;
  currency: string;
  unicodeFlag: string;
  flag: string;
  dialCode: string;
}

export interface CountiresCurrency {
  name: string;
  currency: string;
  iso2?: string;
  iso3?: string;
}

export const countriesApi = createApi({
  reducerPath: "countries",
  baseQuery: fetchBaseQuery({
     baseUrl: "https://countriesnow.space/api/v0.1/",
     prepareHeaders: (headers, { getState }) => {
        headers.set("Access-Control-Allow-Origin", "*");
      return headers;
    }
    }),
  tagTypes: ["Countries", "CountriesDial", "CountryStates","CountryCurrency","CountriesInfo"],
  refetchOnFocus: true,
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    getCountries: builder.query<IRes<any[]>, void>({
      query: () => "countries/flag/images",
      providesTags: [{ type: "Countries", id: "LIST" }],
    }),
    getCountriesWithDial: builder.query<IRes<{ name: string; dial_code: string }[]>, void>({
      query: () => "countries/codes",
      providesTags: [{ type: "CountriesDial", id: "LIST" }],
    }),
    getCountriesInfo: builder.query<IRes<CountiresInfo[]>, void>({
      query: () => "countries/info?returns=currency,flag,unicodeFlag,dialCode",
      providesTags: [{ type: "CountriesInfo", id: "LIST" }],
    }),
    getCountriesCurrency: builder.query<IRes<CountiresCurrency[]>, void>({
      query: () => "countries/currency",
      providesTags: [{ type: "CountryCurrency", id: "LIST" }],
    }),
    getStatesForCountry: builder.query<IRes<{ states: any[] }>, { country: string }>({
      query: (body: { country: string }) => ({
        url: `countries/states`,
        method: "POST",
        body,
      }),
      providesTags: (result, error, arg) => [{ type: "CountryStates", id: arg.country }],
    }),
  }),
});

export const {
  useGetCountriesQuery, useGetCountriesWithDialQuery, useGetStatesForCountryQuery, useGetCountriesInfoQuery, useGetCountriesCurrencyQuery} =
  countriesApi;
