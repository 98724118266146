import { createApi } from "@reduxjs/toolkit/query/react";
import { IApiResponse, IPaginate } from "../../../interfaces/common";
import { ILabTest } from "../../../interfaces/lab";
import { baseQuery } from "../common";

export const radiologyTestApi = createApi({
    reducerPath: "radiologyTest",
    baseQuery: baseQuery,
    tagTypes: ["RadiologyTests", "RadiologyTest"],
    refetchOnFocus: false,
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
    endpoints: (builder) => ({
        getRadiologyTest: builder.query<IApiResponse<IPaginate<ILabTest[]>>, ({pagination: { page: number; limit: number }})>({
            query: ({pagination: {page=1,limit=10}}) => `/radiology?page=${page}&limit=${limit}`,
            providesTags: [{ type: "RadiologyTests", id: "LIST"}],
        }),
        getRadiologyForAnId: builder.query<IApiResponse<ILabTest>, string>({
            query: (id) => `/radiology/${id}`,
            providesTags: (result, error, arg) => [{ type: "RadiologyTest", id: arg }],
        }),
        addRadiologyTest: builder.mutation<ILabTest, Partial<ILabTest>>({
            query: (body) => ({
                url: `/radiology`,
                method: "POST", 
                body,
            }),
            invalidatesTags: ["RadiologyTests", "RadiologyTest"],
        }),
        setDefaultTest: builder.mutation<any, Partial<ILabTest>>({
            query(body){
                return {
                    url: `radiology/setup`,
                    method: "POST",
                    body,
                }
            },
            invalidatesTags: ["RadiologyTests", "RadiologyTest"],
        }),
        searchTest: builder.query<
        IApiResponse<IPaginate<ILabTest[]>>,
        {
            name?: string;
            category?: string;
            categoryId?: string;
            pagination: { page?: number; limit?: number };
        }
        >({
        query: ({
            name,
            category,
            categoryId,
            pagination: { page = 1, limit = 10 },
        }) => `/radiology/filter?page=${page}&limit=${limit}${name && `&name=${name}`}`,
        providesTags: [{ type: "RadiologyTests" }],
        }),
        updateRadiologyTest: builder.mutation<ILabTest, Partial<ILabTest>>({
            query(data){
                return {
                    url: `/radiology/${data.id}`,
                    method: "PATCH",
                    body: data,
                };
            },
            invalidatesTags: (result, error, arg) => [{ type: "RadiologyTest", id: arg.id }, "RadiologyTests"], 
        }),
        deleteRadiologyTest: builder.mutation<ILabTest, Partial<ILabTest>>({
            query:({id}) => ({
                url: `/radiology/${id}`, 
                method: "DELETE",
                body: id,
            }),
            invalidatesTags: ["RadiologyTests", "RadiologyTest"]
        })
    }),
});


export const {
    useGetRadiologyTestQuery,
    useGetRadiologyForAnIdQuery,
    useAddRadiologyTestMutation,
    useUpdateRadiologyTestMutation,
    useSetDefaultTestMutation,
    useDeleteRadiologyTestMutation,
    usePrefetch
} = radiologyTestApi