import React from "react";
import Guides from "../data/SuperAdminLeftNavItems";
import Logo from "../assets/images/logo.png";
import { useHistory } from "react-router-dom";

const LeftSideNav = () => {
  const history = useHistory();
  return (
    <div className="bg-black h-100">
      <nav
        id="sidebarMenu"
        className=" d-md-block min-vh-100  sidebar collapse "
      >
        <div className="position-sticky pt-1">
          <div className="py-3 ps-4">
            <img
              src={Logo}
              alt="logo"
              className="img-fluid"
              style={{ maxHeight: "40px" }}
            />
          </div>

          <ul className="nav flex-column">
            {Guides.map((item, index) => (
              <li className="nav-item py-2 " key={index}>
                <a
                  className="nav-link text-white"
                  href={`/admin/${item.path}`}
                  onClick={(e) => {
                    e.preventDefault();
                    item.path && history.push(`/admin/${item.path}`);
                  }}
                >
                  <div
                    className="me-2 d-inline-block"
                    style={{ width: "1.5rem" }}
                  >
                    <i className={`${item.icon} `}></i>
                  </div>
                  <span>{item.name}</span>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default LeftSideNav;
