import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AdminState } from "../types";
import { RootState, AppThunk } from "../../app/store";
import axios from "axios";
const { REACT_APP_BASEURL } = process.env;

const initialState: AdminState = {
  type: "hospital",
  timeout: false,
  adminSignup: "",
  isLoading: false,
  isAuthenticated: false,
  id: "",
  phoneNumber: "",
  userEmail: "",
  hospitalId: "",
  address: "",
  email: "",
  credentials: {
    email: "",
    password: "",
  },
  roles: [],
  token: "",
  authError: "",
  availability: 0,
};

async function registerHAM(token: string, userObj: object) {
  try {
    const response = await axios.post(
      `${REACT_APP_BASEURL}auth/loginbytoken/${token}`,
      userObj
    );
    console.log(response.data);
    return response.data;
  } catch (error: any) {
    console.log(error.response);
    return error.response?.data.message;
    // return error.response.statusText
  }
}

async function registerUser(token: string, userPassword: object) {
  try {
    const response = await axios.post(
      `${REACT_APP_BASEURL}users/verify/${token}`,
      userPassword
    );
    console.log(response.data);
    return response.data;
  } catch (error: any) {
    console.log(error.response);
    return error.response?.data.message;
    // return error.response.statusText
  }
}

async function loginUser(param: number, loginInfo: object) {
  try {
    const response = await axios.post(
      `${REACT_APP_BASEURL}auth/login/otp/${param}`,
      loginInfo
    );
    console.log(response.data);
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
}

export const registerAsync = createAsyncThunk(
  "auth/registerUser",
  async (signupInfo: any) => {
    const response = await registerHAM(signupInfo.token, signupInfo.data);
    console.log(signupInfo, "singinInfo");
    console.log(response);
    return response;
  }
);

export const registerUserAsync = createAsyncThunk(
  "auth/registerUserAsync",
  async (userInfo: any) => {
    const response = await registerUser(userInfo.token, userInfo.data);
    console.log(userInfo, "userInfo");
    console.log(response);
    return response;
  }
);

export const loginAsync = createAsyncThunk(
  "auth/loginUsers",
  async (loginInfo: any) => {
    const response = await loginUser(loginInfo.param, loginInfo.data);
    return response;
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    getAvailability: (state, action) => {
      return {
        ...state,
        availability: action.payload,
      };
    },
    getCredentials: (state, action) => {
      return {
        ...state,
        credentials: {
          email: action.payload.email,
          password: action.payload.password,
        },
      };
    },
    getUserEmail: (state, action) => {
      return {
        ...state,
        userEmail: action.payload.userEmail,
      };
    },
    loguserOut: (state) => {
      return {
        ...state,
        isAuthenticated: false,
      };
    },
    timeoutFunc: (state, action) => {
      console.log(action.payload);
      return {
        ...state,
        timeout: action.payload.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(registerAsync.fulfilled, (state, action: any) => {
        console.log(action.payload, "from store");
        if (action?.payload?.payload?.token) {
          const {
            fullname,
            hospitalId,
            address,
            phoneNumber,
            email,
            dialCode,
          } = action.payload?.payload?.user;
          const { civisHospitalId, name, country } =
            action.payload?.payload?.hospital;
          localStorage.setItem("email", email);
          localStorage.setItem("userId", action.payload.payload.user.id);
          localStorage.setItem("token", action?.payload?.payload?.token);
          const data = {
            hospitalId,
            address,
            dialCode,
            phoneNumber,
            fullname,
            email,
            name,
            civisHospitalId,
            country,
          };
          localStorage.setItem("hospital_info", JSON.stringify(data));
          console.log(data, "data");
          return {
            ...state,
            fullname,
            hospitalId,
            address,
            name,
            isLoading: false,
            // isAuthenticated: true,
          };
        } else {
          return {
            ...state,
            isLoading: false,
            authError: action.payload?.message,
          };
        }
      })
      .addCase(registerAsync.rejected, (state, action: any) => {
        return {
          ...state,
          isLoading: false,
          authError: "Network Error",
        };
      })

      .addCase(registerUserAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(registerUserAsync.fulfilled, (state, action: any) => {
        if (action?.payload?.payload?.token) {
          const {
            fullname,
            hospitalId,
            address,
            phoneNumber,
            dialCode,
            email,
          } = action.payload?.payload?.user;
          const { civisHospitalId, name, country } =
            action.payload?.payload?.hospital;
          localStorage.setItem("email", email);
          localStorage.setItem("userId", action.payload.payload.user.id);
          localStorage.setItem("token", action?.payload?.payload?.token);
          const data = {
            hospitalId,
            address,
            phoneNumber,
            dialCode,
            fullname,
            email,
            name,
            civisHospitalId,
            country,
          };
          localStorage.setItem("hospital_info", JSON.stringify(data));
          //console.log(data, "data");
          // const data2 = action.payload?.payload?.role?.permissions;
          // localStorage.setItem("user_permission", JSON.stringify(data2))
          return {
            ...state,
            fullname,
            hospitalId,
            address,
            name,
            isLoading: false,
            // isAuthenticated: true,
          };
        } else {
          return {
            ...state,
            isLoading: false,
            authError: action.payload?.message,
          };
        }
      })
      .addCase(registerUserAsync.rejected, (state, action: any) => {
        return {
          ...state,
          isLoading: false,
          authError: "Network Error",
        };
      })

      .addCase(loginAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loginAsync.fulfilled, (state, action: any) => {
        if (action.payload?.payload?.token) {
          console.log(action.payload?.payload, "details");
          const {
            fullname,
            hospitalId,
            address,
            email,
            phoneNumber,
            firstName,
            lastName,
            middleName,
            title,
            roleId,
          } = action.payload.payload.user;
          const { civisHospitalId, name, country } =
            action.payload?.payload?.hospital;
          localStorage.setItem("token", action.payload.payload.token);
          localStorage.setItem("userId", action.payload.payload.user.id);
          const data = {
            hospitalId,
            address,
            email,
            phoneNumber,
            fullname,
            civisHospitalId,
            name,
            firstName,
            lastName,
            middleName,
            title,
            country,
            roleId,
          };
          localStorage.setItem("hospital_info", JSON.stringify(data));
          //console.log(data);
          const data2 = action.payload?.payload?.role?.permissions;
          localStorage.setItem("user_permission", JSON.stringify(data2));
          return {
            ...state,
            hospitalId,
            address,
            email,
            isLoading: false,
            isAuthenticated: true,
          };
        } else {
          console.log(action.payload?.message);
          return {
            ...state,
            isLoading: false,
            authError: action.payload?.message,
          };
        }
      })
      .addCase(loginAsync.rejected, (state, action: any) => {
        return {
          ...state,
          isLoading: false,
          authError: "Network Error",
        };
      });
  },
});

export const {
  timeoutFunc,
  loguserOut,
  getAvailability,
  getCredentials,
  getUserEmail,
} = authSlice.actions;

export const selectStateValues = (state: RootState) => state.auth;

export default authSlice.reducer;

// export const { authStart } = userSlice.actions;
// export const userDetails = (state: RootState) => state.user;

// export default userSlice.reducer
