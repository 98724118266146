import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { IAdmissionObject } from "../../../interfaces/admission";
import { Room } from "../../../interfaces/block";
import { IApiResponse, IPaginate } from "../../../interfaces/common";
import { baseQuery } from "../common";

export const admissionApi = createApi({
  reducerPath: "admission",
  baseQuery: baseQuery,
  tagTypes: ["Admission", "Admissions"],
  refetchOnFocus: true,
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    getAdmissions: builder.query<
      IApiResponse<IPaginate<Room[]>>,
      {
        pagination: { page: number; limit: number };
      }
    >({
      query: ({ pagination: { page = 1, limit = 10 } }) =>
        `/rooms?page=${page}&limit=${limit}`,
      providesTags: [{ type: "Admissions", id: "LIST" }],
    }),
  }),
});

export const { useGetAdmissionsQuery } = admissionApi;
