import React from "react";

export interface IDepartmentTable {
  name: string;
  count: number;
}

const DepartmentTable: React.FC<IDepartmentTable> = (props) => {
  return (
    <tr key={props.name}>
      <td>{props.name}</td>
      <td>{props.count}</td>
    </tr>
  );
};

export default DepartmentTable;
