import { createApi } from "@reduxjs/toolkit/query/react";
import { IApiResponse, IPaginate } from "../../interfaces/common";
import { IUser } from "../../interfaces/user";
import { baseQuery } from "./common";

export const userApi = createApi({
  reducerPath: "user",
  baseQuery: baseQuery,
  tagTypes: ["Users", "User"],
  refetchOnFocus: true,
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    // getUsers: builder.query<IApiResponse<IPaginate<IUser[]>>, void>({
    //   query: () => "/users",
    //   providesTags: [{ type: "Users", id: "LIST" }],s
    // }),
    getUsers: builder.query<
      IApiResponse<IPaginate<IUser[]>>,
      { name; email; status; pagination: { page?: number; limit?: number } }
    >({
      query: ({ name, email, status, pagination: { page = 1, limit = 10 } }) =>
        `/users?page=${page}&limit=${limit}${name && `&name=${name}`}${email && `&email=${email}`}${status && `&status[]=${status}`}`,
      providesTags: [{ type: "Users", id: "LIST" }],
    }),
    getAllUsers: builder.query<
      IApiResponse<IPaginate<IUser[]>>,
      { pagination: { page: number; limit: number } }
    >({
      query: () => "/users?status[]=active&limit=10000",
      providesTags: [{ type: "Users", id: "LIST" }],
    }),
    getUser: builder.query<IApiResponse<IUser>, string>({
      query: (id) => `/users/${id}`,
      providesTags: (result, error, arg) => [{ type: "User", id: arg }],
    }),
    // searchRoles: builder.query<IApiResponse<IPaginate<IUser[]>>,  ({name,pagination: { page?: number; limit?: number }})>({
    //   query: ({name,pagination:{page=1,limit=10}}) => `/users?page=${page}&limit=${limit}${name && `&name=${name}`}`,
    //   providesTags: [{ type: "Users", id: "LIST" }],
    // }),
    addUser: builder.mutation<IUser, Partial<IUser>>({
      query: (body) => ({
        url: `users`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Users", "User"],
    }),
    updateUser: builder.mutation<IUser, Partial<IUser>>({
      query(data) {
        return {
          url: `users/${data.id}`,
          method: "PATCH",
          body: data,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "User", id: arg.id },
        "Users",
      ],
    }),
    updateProfile: builder.mutation<IUser, Partial<IUser>>({
      query(data) {
        return {
          url: `users/profile`,
          method: "PATCH",
          body: data,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "User", id: arg.id },
        "Users",
      ],
    }),
    deleteUser: builder.mutation<IUser, Partial<IUser>>({
      query: ({ id }) => ({
        url: `/users/${id}`,
        method: "DELETE",
        body: id,
      }),
      invalidatesTags: ["Users"],
    }),

    getStaffProfile: builder.query<IApiResponse<IUser>, number>({
      query: () => `/users/profile`,
      providesTags: [{ type: "Users", id: "LIST" }],
      keepUnusedDataFor: 300,
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetAllUsersQuery,
  useGetUserQuery,
  useAddUserMutation,
  useUpdateUserMutation,
  useUpdateProfileMutation,
  useDeleteUserMutation,
  useGetStaffProfileQuery,
  // useSearchRolesQuery
} = userApi;
