import React from "react";
import { Col, Row } from "react-bootstrap";

export interface IUserCard {
  name: string;
  count: number;
  icon: string;
}

const BoxForUsers: React.FC<any> = ({ data }) => (
  <Row
    className="shadow border rounded-3 mx-1 mb-3 p-2"
    style={{ minWidth: "13vw", minHeight: "15vh" }}
  >
    <Col className="d-flex flex-column">
      <small className="fw-bold text-primary" style={{ fontSize: "0.9rem" }}>
        {data?.count}
      </small>
      <small className="mt-2">{data?.name}</small>
    </Col>
    <Col className="d-flex justify-content-end">
      <img src={data?.icon} alt="icon" height={20} width={20} />
    </Col>
  </Row>
);

const UserCard: React.FC<IUserCard> = (props) => {
  return (
    <Col key={props.name}>
      <BoxForUsers data={props} />
    </Col>
  );
};

export default UserCard;
