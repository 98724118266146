import { createApi } from "@reduxjs/toolkit/query/react";
import { IApiResponse, IPaginate } from "../../../interfaces/common";
import { IRole, RolePermissionsProp } from "../../../interfaces/roles";
import { baseQuery } from "../common";

export const RolesCheckApi = createApi({
  reducerPath: "RolesCheck",
  baseQuery: baseQuery,
  tagTypes: ["Role", "Roles"],
  refetchOnFocus: true,
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    getRoles: builder.query<
      IApiResponse<IPaginate<IRole[]>>,
      { name; pagination: { page?: number; limit?: number } }
    >({
      query: ({ name, pagination: { page = 1, limit = 10 } }) =>
        `/roles?page=${page}&limit=${limit}${name && `&name=${name}`}`,
      providesTags: [{ type: "Roles", id: "LIST" }],
    }),
    getAllRoles: builder.query<IApiResponse<IPaginate<IRole[]>>, void>({
      query: () => `/roles?limit=${1000}`,
      providesTags: [{ type: "Roles", id: "LIST" }],
    }),
    getRoleForAnId: builder.query<IApiResponse<IRole>, string>({
      query: (id) => `/roles/${id}`,
      providesTags: (result, error, arg) => [{ type: "Roles", id: arg }],
    }),
    getModulesForRole: builder.query<IApiResponse<any>, string>({
      query: (id) => `/roles/${id}/module/permissions`,
      providesTags: (result, error, arg) => [{ type: "Roles", id: arg }],
    }),
    updateRole: builder.mutation<IRole, Partial<IRole>>({
      query(data) {
        return {
          url: `roles/${data.id}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: ["Roles"],
    }),
    assignPermissionToRole: builder.mutation<IRole, Partial<IRole>>({
      query(data) {
        return {
          url: `/roles/${data.id}/assign`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: (result, error, arg) => [{ type: "Roles", id: arg.id }],
    }),
    unAssignPermissionToRole: builder.mutation<IRole, Partial<IRole>>({
      query(data) {
        return {
          url: `/roles/${data.id}/unassign`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: (result, error, arg) => [{ type: "Roles", id: arg.id }],
    }),
    duplicateRole: builder.mutation<IRole, Partial<IRole>>({
      query(data) {
        return {
          url: `roles/${data.id}/duplicate`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["Roles"],
    }),
    getRolePermissionsByRoleId: builder.query<
      IApiResponse<RolePermissionsProp[]>,
      string
    >({
      query: (id) => `/permissions/role/${id}`,
      providesTags: [{ type: "Roles", id: "LIST" }],
    }),
    permissionAssignmentToRole: builder.mutation<
      { [key: string]: string },
      Partial<{ [key: string]: string }>
    >({
      query(data) {
        return {
          url: `/role-permissions`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["Roles"],
    }),
    permissionUnAssignmentToRole: builder.mutation<
      { [key: string]: string },
      Partial<{ [key: string]: string }>
    >({
      query(data) {
        return {
          url: `/role-permissions/remove`,
          method: "DELETE",
          body: data,
        };
      },
      invalidatesTags: ["Roles"],
    }),
    createRole: builder.mutation<IRole, Partial<IRole>>({
      query: (body) => ({
        url: `roles`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Roles"],
    }),
  }),
});

export const {
  useGetRolesQuery,
  useGetAllRolesQuery,
  useGetRoleForAnIdQuery,
  useGetModulesForRoleQuery,
  useUpdateRoleMutation,
  useDuplicateRoleMutation,
  useAssignPermissionToRoleMutation,
  useUnAssignPermissionToRoleMutation,
  useGetRolePermissionsByRoleIdQuery,
  usePermissionAssignmentToRoleMutation,
  usePermissionUnAssignmentToRoleMutation,
  useCreateRoleMutation,
} = RolesCheckApi;
