import { createApi } from "@reduxjs/toolkit/query/react";
import { IApiResponse, IPaginate } from "../../interfaces/common";
import { Ivendor } from "../../interfaces/vendor";
import { baseQuery } from "./common";

export const vendorApi = createApi({
  reducerPath: "vendors",
  baseQuery: baseQuery,
  tagTypes: ["Vendors", "Vendor"],
  refetchOnFocus: true,
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    getVendors: builder.query<
      IApiResponse<IPaginate<Ivendor[]>>,
      { pagination: { page: number; limit: number } }
    >({
      query: ({ pagination: { page = 1, limit = 10 } }) =>
        `/vendor?page=${page}&limit=${limit}`,
      providesTags: [{ type: "Vendors", id: "LIST" }],
    }),
    getVendorsById: builder.query<IApiResponse<Ivendor>, string>({
      query: (id) => `/vendor/${id}`,
      //   keepUnusedDataFor: 300,
      providesTags: (result, error, arg) => [{ type: "Vendor", id: arg }],
    }),
    addVendor: builder.mutation<Ivendor, Partial<Ivendor>>({
      query: (body) => ({
        url: `vendor`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Vendors", "Vendor"],
    }),
    updateVendor: builder.mutation<Ivendor, Partial<Ivendor>>({
      query(data) {
        return {
          url: `vendor/${data.id}`,
          method: "PATCH",
          body: data,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Vendor", id: arg.id },
        "Vendor",
      ],
    }),
    deleteVendor: builder.mutation<Ivendor, Partial<Ivendor>>({
      query: ({ id }) => ({
        url: `vendor/${id}`,
        method: "DELETE",
        body: id,
      }),
      invalidatesTags: ["Vendor", "Vendor"],
    }),
  }),
});

export const {
  useGetVendorsQuery,
  useAddVendorMutation,
  useGetVendorsByIdQuery,
  useUpdateVendorMutation,
  useDeleteVendorMutation,
} = vendorApi;

