import React from "react";
import { Col } from "react-bootstrap";
import { IDepartmentTable } from "./DepartmentTable";

const LabAndRadCard: React.FC<IDepartmentTable> = (props) => {
  return (
    <Col className="d-flex flex-column mt-3 p-3">
      <small className="fw-bold text-primary">{props.count}</small>
      <small>{props.name}</small>
    </Col>
  );
};

export default LabAndRadCard;
