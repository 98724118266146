import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { loguserOut, timeoutFunc } from "../app/hospitalRedux/userSlice";
import { useHistory } from "react-router-dom";

const PasswordChangedSuccess = ({ exitModal, showModal }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [countDown, setCountDown] = useState(10);

    const handleLogout = () => {
        dispatch(loguserOut());
        localStorage.clear();
        dispatch(timeoutFunc({ payload: false }))
        //  window.location.reload();
        history.push("/hospital/login");
    };

    useEffect(() => {

        let myInterval = setInterval(() => {
            if (countDown > 0) {
                setCountDown(countDown - 1);
            }
            else if (countDown == 0) {
                handleLogout()
            }

        }, 1000)
        return () => {
            clearInterval(myInterval);
        };
    }, [countDown]);
    return (
        <Modal centered show={showModal}>
            <Modal.Header className="text-primary px-4 d-flex justify-content-end">
                <i className="bi bi-x-circle" onClick={exitModal} style={{ cursor: 'pointer' }}></i>
            </Modal.Header>
            <Modal.Body className=" p-3 d-flex flex-column justify-content-center align-items-center">
                <div className="d-flex flex-column gap-3 px-2  align-items-center">
                    <div className="d-flex w-100 justify-content-center  align-items-center gap-3">
                        <i className="bi text-success bi-check-circle-fill" style={{ fontSize: '5rem' }}></i>
                        <p className="m-0">
                            You have successfully changed your password.
                            Hence, You are required to login with your new password to continue.
                        </p>
                    </div>

                    <p className="m-0 my-3 text-end w-100 px-3">{`${'Logging out in'} ${countDown}s`} </p>
                </div>
                <div className="w-100">


                </div>

            </Modal.Body>
        </Modal>
    )
}
export default PasswordChangedSuccess;