import { createApi } from "@reduxjs/toolkit/query/react";
import { IApiResponse, IPaginate } from "../../interfaces/common";
import { ISpecialization } from "../../interfaces/specialization";
import { baseQuery } from "./common";

export const specializationApi = createApi({
  reducerPath: "specialization",
  baseQuery: baseQuery,
  tagTypes: ["Specializations"],
  refetchOnFocus: true,
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    getSpecializations: builder.query<
      IApiResponse<IPaginate<ISpecialization[]>>,
      { pagination: { page: number; limit: number } }
    >({
      query: ({ pagination: { page = 1, limit = 10 } }) =>
        `/specialization?page=${page}&limit=${limit}`,
      providesTags: ["Specializations"],
    }),
    getSpecializationsSearch: builder.query<
      IApiResponse<IPaginate<ISpecialization[]>>,
      void
    >({
      query: () => `/specialization?limit=${1000}`,
      providesTags: ["Specializations"],
    }),
    getSpecializationDefault: builder.query<
      IApiResponse<ISpecialization[]>,
      void
    >({
      query: () => `/specialization/default`,
      providesTags: ["Specializations"],
    }),
    addSpecialization: builder.mutation<
      ISpecialization,
      Partial<ISpecialization>
    >({
      query: (body) => ({
        url: `specialization`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Specializations"],
    }),
    updateSpecialization: builder.mutation<
      ISpecialization,
      Partial<ISpecialization>
    >({
      query(data) {
        return {
          url: `specialization/${data.id}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: ["Specializations"],
    }),
    getSpecializationById: builder.query<IApiResponse<ISpecialization>, string>(
      {
        query: (id) => `specialization/${id}`,
        providesTags: (result, error, arg) => [
          { type: "Specializations", id: arg },
        ],
      },
    ),
  }),
});

export const {
  useGetSpecializationsQuery,
  useGetSpecializationDefaultQuery,
  useAddSpecializationMutation,
  useUpdateSpecializationMutation,
  useGetSpecializationByIdQuery,
} = specializationApi;
