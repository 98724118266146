import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { pb } from "../../constants/package";
import { NotificationResponse, PbNotification } from "../../interfaces/notification";


const today = new Date();
today.setDate(today.getDate() - 30);
const formattedDate = today.toISOString().slice(0, 10); // YYYY-MM-DD format

export const notificationApi = createApi({
    reducerPath: 'pocketbaseApi',
    baseQuery: fetchBaseQuery({ baseUrl: pb.baseUrl }),
    tagTypes: ["Notifications", "Notification"],
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
    endpoints: (builder) => ({
        getNotifications: builder.query<NotificationResponse, string>({
            query: (userId) => {
                return {
                    url: `api/collections/notification/records`,
                    params: {
                        perPage: 500,
                        filter: `created >= "${formattedDate}" && userId= "${userId}"`,
                        sort: `-created`
                    },
                }
            },
            providesTags: [{ type: "Notifications", id: "LIST" }],
        }),

        getUnreadNotifications: builder.query<NotificationResponse, string>({
            query: (userId) => {
                return {
                    url: `api/collections/notification/records`,
                    params: {
                        perPage: 500,
                        filter: `created >= "${formattedDate}" && userId = "${userId}" && readStatus = false`,
                        sort: `-created`
                    },
                }
            },
            providesTags: [{ type: "Notifications", id: "LIST" }],
        }),

        readNotification: builder.mutation<PbNotification, Required<PbNotification>>({
            query: (data) => {
                return {
                    url: `api/collections/notification/records/${data?.id}`,
                    method: "PATCH",
                    body: {
                        ...data,
                        readStatus: true,
                    },
                };
            },
            invalidatesTags: (result, error, arg) => [
                { type: "Notification", id: arg.id },
                "Notification",
            ],
        }),

        readAllNotification: builder.mutation<PbNotification, Required<PbNotification[]>>({
            query: (data) => {
                return {
                    url: `api/collections/notification/records`,
                    method: "PATCH",
                    body: data.map((record) => ({
                        ...record,
                        readStatus: true,
                    })),
                };
            },
            invalidatesTags: ["Notifications"],
        })
    })
})

export const {
    useGetNotificationsQuery,
    useGetUnreadNotificationsQuery,
    useReadNotificationMutation,
    useReadAllNotificationMutation,
} = notificationApi;