import { createApi } from "@reduxjs/toolkit/query/react";
import { DateRange } from "rsuite/esm/DateRangePicker";
import { IApiResponse, IPaginate } from "../../../interfaces/common";
import { IReport, ReportModules } from "../../../interfaces/reports";
import { baseQuery } from "../common";
import moment from "moment";

export const reportApi = createApi({
  reducerPath: "reports",
  baseQuery: baseQuery,
  tagTypes: ["Reports", "Report"],
  refetchOnFocus: false,
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: false,
  endpoints: (builder) => ({
    getReport: builder.query<
      IApiResponse<IPaginate<any[]>>,
      {
        pagination: { page: number; limit: number };
        reportType: string | undefined;
        sortDate: { start: string; end: string } | undefined;
      }
    >({
      query: ({ pagination: { page = 1, limit = 10 }, reportType, sortDate }) =>
        `report/${reportType}/${sortDate?.start}/${sortDate?.end}?page=${page}&limit=${limit}`,
      providesTags: [{ type: "Reports", id: "LIST" }],
    }),

    getReportRefactored: builder.query<
      IApiResponse<IPaginate<ReportModules[]>>,
      {
        pagination: { page: number; limit: number };
        reportType: string | undefined;
        start?: string;
        end?: string;
      }
    >({
      query: ({
        pagination: { page = 1, limit = 10 },
        reportType,
        start,
        end,
      }) => `report/${reportType}/${start}/${end}?page=${page}&limit=${limit}`,
      providesTags: [{ type: "Reports", id: "LIST" }],
    }),

    getAllReportRefactored: builder.query<
      IApiResponse<IPaginate<ReportModules[]>>,
      {
        pagination: { page: number; limit: number };
        reportType: string | undefined;
        start?: string;
        end?: string;
      }
    >({
      query: ({
        pagination: { page = 1, limit = 10 },
        reportType,
        start,
        end,
      }) => `report/${reportType}/${start}/${end}?page=${page}&limit=1000`,
      providesTags: [{ type: "Reports", id: "LIST" }],
    }),

    getReportAll: builder.query<
      IApiResponse<IPaginate<any[]>>,
      {
        pagination: { page: number; limit: number };
        reportType: string | undefined;
        sortDate: { start: string; end: string } | undefined;
      }
    >({
      query: ({ pagination: { page = 1, limit = 10 }, reportType, sortDate }) =>
        `report/${reportType}/${sortDate?.start}/${sortDate?.end}?page=${page}&limit=1000`,
      providesTags: [{ type: "Reports", id: "LIST" }],
    }),
  }),
});

export const {
  useGetReportQuery,
  useGetReportAllQuery,
  useGetReportRefactoredQuery,
  useGetAllReportRefactoredQuery,
} = reportApi;
