import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface TimerState {
  isRunning: boolean;
  startTime: number | null;
  remainingTime: number;
  duration: number;
}

const initialState: TimerState = {
  isRunning: false,
  startTime: null,
  remainingTime: 0,
  duration: 5000,
};

const timerSlice = createSlice({
  name: "hospitalActivationTimer",
  initialState,
  reducers: {
    start: (state, action: PayloadAction<number>) => {
      state.isRunning = true;
      state.startTime = action.payload;
      state.remainingTime = state.duration;
    },
    stop: (state) => {
      state.isRunning = false;
      state.startTime = null;
      state.remainingTime = 0;
    },
    tick: (state, action: PayloadAction<number>) => {
      state.remainingTime = action.payload;
    },
  },
});

export const { start, stop, tick } = timerSlice.actions;

export const selectTimer = (state: RootState) => state.hospitalActivationTimer;

export default timerSlice.reducer;
