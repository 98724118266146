import { createApi } from "@reduxjs/toolkit/query/react";
import { IMedicationType } from "../../interfaces/brandsCateMed";
import { IApiResponse, IPaginate } from "../../interfaces/common";
import { baseQuery } from "./common";

export const medicationTypeApi = createApi({
  reducerPath: "medication-type",
  baseQuery: baseQuery,
  tagTypes: ["Medication-types", "Medication-type"],
  refetchOnFocus: true,
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    getMedicationType: builder.query<
      IApiResponse<IPaginate<IMedicationType[]>>,
      { pagination: { page: number; limit: number } }
    >({
      query: ({ pagination: { page = 1, limit = 10 } }) =>
        `/medication-type?page=${page}&limit=${limit}`,
      providesTags: [{ type: "Medication-type", id: "LIST" }],
    }),
    getAllMedicationType: builder.query<
      IApiResponse<IPaginate<IMedicationType[]>>,
      void
    >({
      query: () => "/medication-type?limit=All",
      providesTags: [{ type: "Medication-type", id: "LIST" }],
    }),
    getMedicationTypeById: builder.query<IApiResponse<IMedicationType>, string>(
      {
        query: (id) => `/medication-type/${id}`,
        providesTags: (result, error, arg) => [
          { type: "Medication-type", id: arg },
        ],
      },
    ),
    addMedicationType: builder.mutation<
      IMedicationType,
      Partial<IMedicationType>
    >({
      query: (body) => ({
        url: `medication-type`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Medication-types", "Medication-type"],
    }),
    updateMedicationType: builder.mutation<
      IMedicationType,
      Partial<IMedicationType>
    >({
      query(data) {
        return {
          url: `medication-type/${data.id}`,
          method: "PATCH",
          body: data,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Medication-type", id: arg.id },
        "Medication-type",
      ],
    }),
    deleteMedicationType: builder.mutation<
      IMedicationType,
      Partial<IMedicationType>
    >({
      query: ({ id }) => ({
        url: `medication-type/${id}`,
        method: "DELETE",
        body: id,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Medication-type", id: arg.id },
        "Medication-type",
      ],
    }),
  }),
});

export const {
  useGetMedicationTypeQuery,
  useGetAllMedicationTypeQuery,
  useAddMedicationTypeMutation,
  useGetMedicationTypeByIdQuery,
  useUpdateMedicationTypeMutation,
  useDeleteMedicationTypeMutation,
} = medicationTypeApi;

