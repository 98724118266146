/* eslint-disable @typescript-eslint/no-explicit-any */
// import 'unfetch/polyfill';
import axios from 'axios';
const { REACT_APP_BASEURL } = process.env;


const config:any = (token?:string) => {
  return token?
  {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      // "Access-Control-Allow-Origin": "*"
    } 
  }
    :
  {
    headers: {
      "Content-Type": "application/json",
      // "Access-Control-Allow-Origin": "*"
    } 
  }
}




export default {
  get: async(url: string,token?: string) => {
   try{
      const res = await axios.get(`${REACT_APP_BASEURL}${url}`,config(token))
      return res
    }catch(error:any){
      const status = error.response?.data?.statusCode
      if(status == 401 || status == 403){
        localStorage.clear();
        if (!window.location?.pathname?.includes("/login")) {
          return window.location.href = "/hospital/login";
        }
      }
      return error.response
    }   
  },
  post: async(url: string,body:{[key:string]:string},token?: string) => {
    try{
      const res = await axios.post(`${REACT_APP_BASEURL}${url}`,body,config(token))
      return res
    }catch(error:any){
      const status = error.response?.data?.statusCode
      if(status == 401 || status == 403){
        localStorage.clear();
        if (!window.location?.pathname?.includes("/login")) {
          return window.location.href = "/hospital/login";
        }
      }
      return error.response
    }   
  },
  put: async(url: string,body:{[key:string]:string},token?: string) => {
    try{
      const res = await axios.put(`${REACT_APP_BASEURL}${url}`,body,config(token))
      return res
    }catch(error:any){
      const status = error.response?.data?.statusCode
      if(status == 401 || status == 403){
        localStorage.clear();
        if (!window.location?.pathname?.includes("/login")) {
          return window.location.href = "/hospital/login";
        }
      }
      return error.response
    }   
  },
  patch: async(url: string,body:{[key:string]:string},token?: string) => {
    try{
      const res = await axios.patch(`${REACT_APP_BASEURL}${url}`,body,config(token))
      return res
    }catch(error:any){
      const status = error.response?.data?.statusCode
      if(status == 401 || status == 403){
        localStorage.clear();
        if (!window.location?.pathname?.includes("/login")) {
          return window.location.href = "/hospital/login";
        }
      }
      return error.response
    }   
  },
  delete: async(url: string,token?: string) => {
    try{
       const res = await axios.delete(`${REACT_APP_BASEURL}${url}`,config(token))
       return res
     }catch(error:any){
       const status = error.response?.data?.statusCode
       if(status == 401 || status == 403){
         localStorage.clear();
         if (!window.location?.pathname?.includes("/login")) {
           return window.location.href = "/hospital/login";
         }
       }
       return error.response
     }   
   },
};


