import { createApi } from "@reduxjs/toolkit/query/react";
import {
  ILabRequest,
  ILabResponse,
  ISpecimen,
  ISpecimenRes,
} from "../../../interfaces/lab";
import { IApiResponse, IPaginate } from "../../../interfaces/common";
import { baseQuery } from "../common";

export const labRequestApi = createApi({
  reducerPath: "labRequest",
  baseQuery: baseQuery,
  tagTypes: ["LabRequest"],
  refetchOnFocus: false,
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    getLabRequestForPatient: builder.query<
      IApiResponse<IPaginate<ILabResponse[]>>,
      { pagination: { page: number; limit: number }; id: string }
    >({
      query: ({ pagination: { page = 1, limit = 10 }, id }) =>
        `/lab-request/by-patient-for-hospital/${id}?page=${page}&limit=${limit}`,
      //   keepUnusedDataFor: 300,
      providesTags: [{ type: "LabRequest", id: "LIST" }],
    }),
    getLabRequest: builder.query<
      IApiResponse<IPaginate<ILabRequest[]>>,
      { pagination: { page: number; limit: number } }
    >({
      query: ({ pagination: { page = 1, limit = 10 } }) =>
        `/lab-request?status=specimen-due&page=${page}&limit=${limit}`,
      providesTags: [{ type: "LabRequest", id: "LIST" }],
    }),
    getLabRequestForInprogress: builder.query<
      IApiResponse<IPaginate<ILabRequest[]>>,
      { pagination: { page: number; limit: number } }
    >({
      query: ({ pagination: { page = 1, limit = 10 } }) =>
        `/lab-request?status=specimen-taken&page=${page}&limit=${limit}`,
      providesTags: [{ type: "LabRequest", id: "LIST" }],
    }),
    getLabRequestForCancelled: builder.query<
      IApiResponse<IPaginate<ILabRequest[]>>,
      { pagination: { page: number; limit: number } }
    >({
      query: ({ pagination: { page = 1, limit = 10 } }) =>
        `/lab-request?status=cancelled&page=${page}&limit=${limit}`,
      providesTags: [{ type: "LabRequest", id: "LIST" }],
    }),
    getLabRequestForCompleted: builder.query<
      IApiResponse<IPaginate<ILabRequest[]>>,
      { pagination: { page: number; limit: number } }
    >({
      query: ({ pagination: { page = 1, limit = 10 } }) =>
        `/lab-request?status=result-released&page=${page}&limit=${limit}`,
      providesTags: [{ type: "LabRequest", id: "LIST" }],
    }),
    getLabRequestForAnId: builder.query<
      IApiResponse<Partial<ILabResponse>>,
      string
    >({
      query: (id) => `/lab-request/${id}`,
      //   keepUnusedDataFor: 300,
      providesTags: (result, error, arg) => [{ type: "LabRequest", id: arg }],
    }),
    addLabRequestInternal: builder.mutation<ILabRequest, Partial<ILabRequest>>({
      query: (body) => ({
        url: `/lab-request/create-internal`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["LabRequest"],
    }),
    addLabRequestInternalMultiple: builder.mutation<
      ILabRequest,
      Partial<ILabRequest>
    >({
      query: (body) => ({
        url: `/lab-request/create-multiple-internal`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["LabRequest"],
    }),
    addLabRequestExternal: builder.mutation<ILabRequest, Partial<ILabRequest>>({
      query: (body) => ({
        url: `/lab-request/create-external`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["LabRequest"],
    }),
    addSpecimmen: builder.mutation<ISpecimen, Partial<ISpecimenRes>>({
      query: (body) => ({
        url: `/lab-request/create-specimen`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["LabRequest"],
    }),
    getSearchTest: builder.query<
      IApiResponse<IPaginate<ILabRequest[]>>,
      {
        searchString?: string;
        status?: string;
        dueDateRangeStart?;
        dueDateRangeEnd?;
        requestDateStart?;
        requestDateEnd?;
        pagination: { page?: number; limit?: number };
      }
    >({
      query: ({
        searchString,
        status,
        dueDateRangeStart,
        dueDateRangeEnd,
        requestDateStart,
        requestDateEnd,
        pagination: { page = 1, limit = 10 },
      }) =>
        `/lab-request/filter?page=${page}&limit=${limit}&status=${status}${
          searchString && `&searchString=${searchString}`
        }${dueDateRangeStart && `&dueDateRangeStart=${dueDateRangeStart}`}${
          dueDateRangeEnd && `&dueDateRangeEnd=${dueDateRangeEnd}`
        }${requestDateStart && `&requestDateStart=${requestDateStart}`}${
          requestDateEnd && `&requestDateEnd=${requestDateEnd}`
        }`,
      providesTags: [{ type: "LabRequest", id: "LIST" }],
    }),

    updateLabTest: builder.mutation<ILabRequest, Partial<ILabRequest>>({
      query(data) {
        console.log(data);
        return {
          url: `/lab-request/${data.id}/${data.type}`,
          method: "PATCH",
          body: data,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "LabRequest", id: arg.id },
        "LabRequest",
      ],
    }),

    filterPatientLabTest: builder.query<
      IApiResponse<IPaginate<ILabResponse[]>>,
      {
        pagination: { page: number; limit: number };
        searchTerm?: string;
        status?: string;
        patientId?: string;
        filterDate?: string;
      }
    >({
      query: ({
        pagination: { page = 1, limit = 10 },
        searchTerm,
        status,
        patientId,
        filterDate,
      }) =>
        `lab-request/test-pbm/filter/${patientId}?page=${page}&limit=${limit}${
          status !== undefined && status === "in_progress"
            ? `&status=specimen_taken&status=result_entered&filterOperator=and`
            : status !== undefined && status !== "in_progress"
            ? `&status=${status}`
            : ``
        }${searchTerm !== undefined ? `&selectedTest=${searchTerm}` : ``}${
          filterDate !== undefined ? `&date=${filterDate}` : ``
        }
      `,
      providesTags: [{ type: "LabRequest", id: "LIST" }],
    }),
  }),
});

export const {
  useGetLabRequestForPatientQuery,
  useGetLabRequestQuery,
  useGetLabRequestForInprogressQuery,
  useGetLabRequestForCancelledQuery,
  useGetLabRequestForCompletedQuery,
  useGetLabRequestForAnIdQuery,
  useAddSpecimmenMutation,
  useGetSearchTestQuery,
  useAddLabRequestInternalMutation,
  useAddLabRequestExternalMutation,
  useUpdateLabTestMutation,
  useAddLabRequestInternalMultipleMutation,
  useFilterPatientLabTestQuery,
} = labRequestApi;
