import { createApi } from "@reduxjs/toolkit/query/react";
import { string } from "yup/lib/locale";
import { IAuthUser } from "../../interfaces/auth";
import { IApiResponse } from "../../interfaces/common";
import { baseQuery } from "./common";

export const authApi = createApi({
  reducerPath: "authUser",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getUserByToken: builder.query<IApiResponse<{ user: IAuthUser }>, void>({
      query: () => `/auth/profile`,
    }),
    getEmailFromVerifyToken: builder.query<IApiResponse<{ user: IAuthUser }>, string>({
      query: (token) => `/users/verify/${token}`,
    }),
    verifyUser: builder.mutation<IApiResponse<{ message: string }>, { token: string; body: { password: string } }>({
      query: ({ token, body }) => ({
        url: `users/verify/${token}`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useGetUserByTokenQuery, useGetEmailFromVerifyTokenQuery, useVerifyUserMutation } = authApi;
