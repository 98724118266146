import React, { useState } from "react";
import Guides from "../data/HospitalLeftNavItems";
import Logo from "../assets/images/civislogoonly.png";
import Logo2 from "../assets/images/civic health white2-04.png";
import styles from './component.module.css'
import { useHistory } from "react-router-dom";
import { useAppDispatch } from "../app/hooks";
import { loguserOut, timeoutFunc } from "../app/hospitalRedux/userSlice";
import { useLocation } from "react-router-dom";
import { CheckPermission } from "../pages/HospitalAdmin/Dashboard";
import { labPermissions } from "../pages/HospitalAdmin/labs/labPermissions";

const LeftSide = ({ nav }) => {

  const [activeIndex, setActiveIndex] = React.useState(-1);
  // const handleOnClick = (index: number) => setActiveIndex(index);

  const path: any = useLocation();

  const checker : boolean = false;


  const dispatch = useAppDispatch();
  const history = useHistory();

  const handleOnClick = (index) => {
    console.log(index)
  }


  const handleLogout = () => {
    dispatch(loguserOut());
    localStorage.clear();
    dispatch(timeoutFunc({ payload: false }))
    //  window.location.reload();
    history.push("/hospital/login");
  };

  return (
    <div className={`${nav ? styles.onCont : styles.container} bg-primary gap-2 py-4 d-flex flex-column  h-100 `} style={{ minWidth: '2em' }}>
      <div className="d-flex align-items-center justify-content-center" style={{ maxWidth: '5em', maxHeight: '4em' }}>
        <img src={Logo} height={60} className={`py-2 ${styles.logo}`} />
        <img src={Logo2} height={90} className={`py-2 ${styles.logo2} ml-5`} />
      </div>

      <div className="d-flex h-75 mt-4 text-light">
        <div className="w-100 " style={{ minWidth: '4em' }}>
          <ul className='nav d-flex m-0 p-0  flex-column' style={{ fontSize: '1.1em', listStyle: 'none', zIndex: 1 }}>
            {<li className={`py-2 ${styles.listObject} 
                ${path.pathname.split("/")[2] == Guides[0]?.path ? styles.active : styles.inactive} w-100 d-flex`}

              onClick={(e: any) => {
                Guides[0]?.path && history.push(`/hospital/${Guides[0]?.path}`);
              }}
              style={{ height: '3em', alignItems: 'center', cursor: 'pointer', zIndex: '1' }} >
              {
                <div className="d-flex gap-3 align-items-center" style={{ minWidth: '5em', fontSize: '1em' }}>
                  <i className={`${Guides[0]?.icon} ml-4`}></i>
                  {
                    <span className={`${styles.leftnavBar}`}>{Guides[0]?.name}</span>
                  }
                </div>

              }
            </li>}

            {CheckPermission('View Appointment') && <li className={`py-2 ${styles.listObject} 
                ${path.pathname.split("/")[2] == Guides[1]?.path ? styles.active : styles.inactive} w-100 d-flex`}

              onClick={(e: any) => {
                Guides[1]?.path && history.push(`/hospital/${Guides[1]?.path}`);
              }}
              style={{ height: '3em', alignItems: 'center', cursor: 'pointer', zIndex: '1' }} >
              {
                <div className="d-flex gap-3 align-items-center" style={{ minWidth: '5em', fontSize: '1em' }}>
                  <i className={`${Guides[1]?.icon} ml-4`}></i>
                  {
                    <span className={`${styles.leftnavBar}`}>{Guides[1]?.name}</span>
                  }
                </div>

              }
            </li>}

            {
              CheckPermission('View Patient List') &&
              <li className={`py-2 ${styles.listObject} 
                ${path.pathname.split("/")[2] == Guides[2]?.path ? styles.active : styles.inactive} w-100 d-flex`}

              onClick={(e: any) => {
                Guides[2]?.path && history.push(`/hospital/${Guides[2]?.path}`);
              }}
              style={{ height: '3em', alignItems: 'center', cursor: 'pointer', zIndex: '1' }} >
              {
                <div className="d-flex gap-3 align-items-center" style={{ minWidth: '5em', fontSize: '1em' }}>
                  <i className={`${Guides[2]?.icon} ml-4`}></i>
                  {
                    <span className={`${styles.leftnavBar}`}>{Guides[2]?.name}</span>
                  }
                </div>

              }
            </li>}

            {CheckPermission('View Products') && <li className={`py-2 ${styles.listObject} 
                ${path.pathname.split("/")[2] == Guides[3]?.path ? styles.active : styles.inactive} w-100 d-flex`}

              onClick={(e: any) => {
                Guides[3]?.path && history.push(`/hospital/${Guides[3]?.path}`);
              }}
              style={{ height: '3em', alignItems: 'center', cursor: 'pointer', zIndex: '1' }} >
              {
                <div className="d-flex gap-3 align-items-center" style={{ minWidth: '5em', fontSize: '1em' }}>
                  <i className={`${Guides[3]?.icon} ml-4`}></i>
                  {
                    <span className={`${styles.leftnavBar}`}>{Guides[3]?.name}</span>
                  }
                </div>

              }
            </li>}

            {CheckPermission('View Vendors') && <li className={`py-2 ${styles.listObject} 
                ${path.pathname.split("/")[2] == Guides[4]?.path ? styles.active : styles.inactive} w-100 d-flex`}

              onClick={(e: any) => {
                Guides[4]?.path && history.push(`/hospital/${Guides[4]?.path}`);
              }}
              style={{ height: '3em', alignItems: 'center', cursor: 'pointer', zIndex: '1' }} >
              {
                <div className="d-flex gap-3 align-items-center" style={{ minWidth: '5em', fontSize: '1em' }}>
                  <i className={`${Guides[4]?.icon} ml-4`}></i>
                  {
                    <span className={`${styles.leftnavBar}`}>{Guides[4]?.name}</span>
                  }
                </div>

              }
            </li>}

            {
              CheckPermission('View Medication Requests')  &&
              <li className={`py-2 ${styles.listObject} 
                ${path.pathname.split("/")[2] == Guides[5]?.path ? styles.active : styles.inactive} w-100 d-flex`}

              onClick={(e: any) => {
                Guides[5]?.path && history.push(`/hospital/${Guides[5]?.path}`);
              }}
              style={{ height: '3em', alignItems: 'center', cursor: 'pointer', zIndex: '1' }} >
              {
                <div className="d-flex gap-3 align-items-center" style={{ minWidth: '5em', fontSize: '1em' }}>
                  <i className={`${Guides[5]?.icon} ml-4`}></i>
                  {
                    <span className={`${styles.leftnavBar}`}>{Guides[5]?.name}</span>
                  }
                </div>

              }
            </li>
            }

            {
            CheckPermission('View Lab Test Requests') && 
            <li className={`py-2 ${styles.listObject} 
                ${path.pathname.split("/")[2] == Guides[6]?.path ? styles.active : styles.inactive} w-100 d-flex`}

              onClick={(e: any) => {
                Guides[6]?.path && history.push(`/hospital/${Guides[6]?.path}`);
              }}
              style={{ height: '3em', alignItems: 'center', cursor: 'pointer', zIndex: '1' }} >
              {
                
                <div className="d-flex gap-3 align-items-center" style={{ minWidth: '5em', fontSize: '1em' }}>
                  <i className={`${Guides[6]?.icon} ml-4`}></i>
                  {
                    <span className={`${styles.leftnavBar}`}>{Guides[6]?.name}</span>
                  }
                </div>

              }
            </li>}

            { 
            CheckPermission("View Blood Bank") &&
            <li className={`py-2 ${styles.listObject} 
                ${path.pathname.split("/")[2] == Guides[7]?.path ? styles.active : styles.inactive} w-100 d-flex`}

              onClick={(e: any) => {
                Guides[7]?.path && history.push(`/hospital/${Guides[7]?.path}`);
              }}
              style={{ height: '3em', alignItems: 'center', cursor: 'pointer', zIndex: '1' }} >
              {
                <div className="d-flex gap-3 align-items-center" style={{ minWidth: '5em', fontSize: '1em' }}>
                  <i className={`${Guides[7]?.icon} ml-4`}></i>
                  {
                    <span className={`${styles.leftnavBar}`}>{Guides[7]?.name}</span>
                  }
                </div>

              }
            </li>}

            {CheckPermission('View Radiology Test Requests') && <li className={`py-2 ${styles.listObject} 
                ${path.pathname.split("/")[2] == Guides[8]?.path ? styles.active : styles.inactive} w-100 d-flex`}

              onClick={(e: any) => {
                Guides[8]?.path && history.push(`/hospital/${Guides[8]?.path}`);
              }}
              style={{ height: '3em', alignItems: 'center', cursor: 'pointer', zIndex: '1' }} >
              {
                <div className="d-flex gap-3 align-items-center" style={{ minWidth: '5em', fontSize: '1em' }}>
                  <i className={`${Guides[8]?.icon} ml-4`}></i>
                  {
                    <span className={`${styles.leftnavBar}`}>{Guides[8]?.name}</span>
                  }
                </div>

              }
            </li>}

            {CheckPermission('View Billing Module') && <li className={`py-2 ${styles.listObject} 
                ${path.pathname.split("/")[2] == Guides[9]?.path ? styles.active : styles.inactive} w-100 d-flex`}

              onClick={(e: any) => {
                Guides[9]?.path && history.push(`/hospital/${Guides[9]?.path}`);
              }}
              style={{ height: '3em', alignItems: 'center', cursor: 'pointer', zIndex: '1' }} >
              {
                <div className="d-flex gap-3 align-items-center" style={{ minWidth: '5em', fontSize: '1em' }}>
                  <i className={`${Guides[9]?.icon} ml-4`}></i>
                  {
                    <span className={`${styles.leftnavBar}`}>{Guides[9]?.name}</span>
                  }
                </div>

              }
            </li>}

            {
              CheckPermission('View Admission') &&
              <li className={`py-2 ${styles.listObject} 
                ${path.pathname.split("/")[2] == Guides[10]?.path ? styles.active : styles.inactive} w-100 d-flex`}

              onClick={(e: any) => {
                Guides[10]?.path && history.push(`/hospital/${Guides[10]?.path}`);
              }}
              style={{ height: '3em', alignItems: 'center', cursor: 'pointer', zIndex: '1' }} >
              {
                <div className="d-flex gap-3 align-items-center" style={{ minWidth: '5em', fontSize: '1em' }}>
                  <i className={`${Guides[10]?.icon} ml-4`}></i>
                  {
                    <span className={`${styles.leftnavBar}`}>{Guides[10]?.name}</span>
                  }
                </div>

              }
            </li>}

            {
              CheckPermission('View Report Module') &&
              <li className={`py-2 ${styles.listObject} 
                ${path.pathname.split("/")[2] == Guides[11]?.path ? styles.active : styles.inactive} w-100 d-flex`}

              onClick={(e: any) => {
                Guides[11]?.path && history.push(`/hospital/${Guides[11]?.path}`);
              }}
              style={{ height: '3em', alignItems: 'center', cursor: 'pointer', zIndex: '1' }} >
              {
                <div className="d-flex gap-3 align-items-center" style={{ minWidth: '5em', fontSize: '1em' }}>
                  <i className={`${Guides[11]?.icon} ml-4`}></i>
                  {
                    <span className={`${styles.leftnavBar}`}>{Guides[11]?.name}</span>
                  }
                </div>

              }
            </li>}

            {false &&
              <li className={`py-2 ${styles.listObject} 
                ${path.pathname.split("/")[2] == Guides[12]?.path ? styles.active : styles.inactive} w-100 d-flex`}

              onClick={(e: any) => {
                Guides[12]?.path && history.push(`/hospital/${Guides[12]?.path}`);
              }}
              style={{ height: '3em', alignItems: 'center', cursor: 'pointer', zIndex: '1' }} >
              {
                <div className="d-flex gap-3 align-items-center" style={{ minWidth: '5em', fontSize: '1em' }}>
                  <i className={`${Guides[12]?.icon} ml-4`}></i>
                  {
                    <span className={`${styles.leftnavBar}`}>{Guides[12]?.name}</span>
                  }
                </div>

              }
            </li>}

            {
              CheckPermission('View Settings') &&
              <li className={`py-2 ${styles.listObject} 
                ${path.pathname.split("/")[2] == Guides[13]?.path ? styles.active : styles.inactive} w-100 d-flex`}

              onClick={(e: any) => {
                Guides[13]?.path && history.push(`/hospital/${Guides[13]?.path}`);
              }}
              style={{ height: '3em', alignItems: 'center', cursor: 'pointer', zIndex: '1' }} >
              {
                <div className="d-flex gap-3 align-items-center" style={{ minWidth: '5em', fontSize: '1em' }}>
                  <i className={`${Guides[13]?.icon} ml-4`}></i>
                  {
                    <span className={`${styles.leftnavBar}`}>{Guides[13]?.name}</span>
                  }
                </div>

              }
            </li>}


          </ul>
          <div

            style={{ cursor: "pointer", minHeight: '3em' }}
            onClick={() => handleLogout()}
            className={`py-2 ${styles.listObject} d-flex nav-item gap-2 logout  py-2 mt-5`}
          >


            <i className="bi bi-box-arrow-right ml-4"></i>

            <span className={`${styles.leftnavBar}`}>Logout</span>

          </div>
        </div>


      </div>

    </div>
  );
};

export default LeftSide;