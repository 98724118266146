import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASEURL, 
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = localStorage.getItem("token");
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

// export const NoTokenbaseQuery = fetchBaseQuery({
//   baseUrl: process.env.REACT_APP_BASEURL,
//   prepareHeaders: (headers, { getState }) => {
//     // By default, if we have a token in the store, let's use that for authenticated requests
//     const token =
//       "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjA1NjY0Y2VjLWZkZDAtNDc1ZC04NGM2LTQzZDIwNzFhMTQ0ZCIsInJvbGUiOjIsImhvc3BpdGFsSWQiOiJkZWZhdWx0IiwiaWF0IjoxNjQxMDM1ODE1LCJleHAiOjE2NDEwODE1OTh9.jiteLFdj_lbbUmJIc7FsKbIb26eoUZoN3ac1R6mrVK8";
//     if (token) {
//       headers.set("authorization", `Bearer ${token}`);
//     }
//     return headers;
//   },
// });
