import { createApi } from "@reduxjs/toolkit/query/react";
import { IBrand } from "../../interfaces/brandsCateMed";
import { IApiResponse, IPaginate } from "../../interfaces/common";
import { baseQuery } from "./common";

export const brandsApi = createApi({
  reducerPath: "brands",
  baseQuery: baseQuery,
  tagTypes: ["Brands", "Brand"],
  refetchOnFocus: true,
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    getBrands: builder.query<
      IApiResponse<IPaginate<IBrand[]>>,
      { pagination: { page: number; limit: number } }
    >({
      query: ({ pagination: { page = 1, limit = 10 } }) =>
        `/brand?page=${page}&limit=${limit}`,
      providesTags: [{ type: "Brands", id: "LIST" }],
    }),
    getBrandsById: builder.query<IApiResponse<IBrand>, string>({
      query: (id) => `/brand/${id}`,
      //   keepUnusedDataFor: 300,
      providesTags: (result, error, arg) => [{ type: "Brand", id: arg }],
    }),
    addBrand: builder.mutation<IBrand, Partial<IBrand>>({
      query: (body) => ({
        url: `brand`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Brands", "Brand"],
    }),
    updateBrand: builder.mutation<IBrand, Partial<IBrand>>({
      query(data) {
        return {
          url: `brand/${data.id}`,
          method: "PATCH",
          body: data,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Brand", id: arg.id },
        "Brands",
      ],
    }),
    deleteBrand: builder.mutation<IBrand, Partial<IBrand>>({
      query: ({ id }) => ({
        url: `brand/${id}`,
        method: "DELETE",
        body: id,
      }),
      invalidatesTags: ["Brands", "Brand"],
    }),
  }),
});

export const {
  useGetBrandsQuery,
  useGetBrandsByIdQuery,
  useAddBrandMutation,
  useUpdateBrandMutation,
  useDeleteBrandMutation,
} = brandsApi;
