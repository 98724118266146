import { createApi } from "@reduxjs/toolkit/query/react";
import { IApiResponse, IPaginate } from "../../interfaces/common";
import { INextOfKin } from "../../interfaces/nextofkin";
import { baseQuery } from "./common";

export const nextOfKinApi = createApi({
  reducerPath: "nextofkins",
  baseQuery: baseQuery,
  tagTypes: ["NextOfKins", "NextOfKin"],
  refetchOnFocus: true,
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    getNextOfKinsForPatient: builder.query<
      IApiResponse<IPaginate<INextOfKin[]>>,
      string
    >({
      query: (id) => `/nextOfKins/patient/${id}`,
      providesTags: ["NextOfKins"],
    }),
    addNextOfKins: builder.mutation<INextOfKin, Partial<INextOfKin>>({
      query: (body) => ({
        url: `nextOfKins`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["NextOfKin", "NextOfKins"],
    }),
    updateNextOfKins: builder.mutation<INextOfKin, Partial<INextOfKin>>({
      query(data) {
        return {
          url: `nextOfKins/${data.id}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "NextOfKin", id: arg.id },
        "NextOfKins",
      ],
    }),
    deleteNextOfKins: builder.mutation<INextOfKin, Partial<INextOfKin>>({
      query(data) {
        return {
          url: `nextOfKins/${data.id}`,
          method: "DELETE",
          body: data,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "NextOfKin", id: arg.id },
        "NextOfKins",
      ],
    }),
  }),
});

export const {
  useGetNextOfKinsForPatientQuery,
  useAddNextOfKinsMutation,
  useUpdateNextOfKinsMutation,
  useDeleteNextOfKinsMutation,
} = nextOfKinApi;
