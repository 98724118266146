import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../common";
import { IApiResponse } from "../../../../interfaces/common";
import { IAddPatientBillingMethod, IPatientBilling, IPatients } from "../../../../interfaces/patients";

export const patientBillingApi = createApi({
    reducerPath: "patientBilling",
    baseQuery: baseQuery,
    tagTypes: ["patientBilling"],
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        getPatientBilling: builder.query<IApiResponse<IPatientBilling>, string>({
            query: (patientId) => `patients/${patientId}/billing`,
            keepUnusedDataFor: 300,
            providesTags: (result, error, arg) => [{ type: "patientBilling", id: arg }],
        }),
        addPatientBillingMethod: builder.mutation<IAddPatientBillingMethod, Partial<IAddPatientBillingMethod>>({
            query: (body) => ({
                url: `patients/${body.patientId}/billing`,
                method: "POST",
                body,
            }),
            invalidatesTags: ["patientBilling"],
        }),
        updatePatientBillingMethod: builder.mutation<IAddPatientBillingMethod, Partial<IAddPatientBillingMethod>>({
            query: (body) => ({
                url: `patients/${body.patientId}/billing/${body.billingId}`,
                method: "PUT",
                body,
            }),
            invalidatesTags: ["patientBilling"],
        }),
    })
})


export const { 
    useGetPatientBillingQuery,
    useAddPatientBillingMethodMutation,
    useUpdatePatientBillingMethodMutation,
 } = patientBillingApi;