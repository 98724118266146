import { createApi } from "@reduxjs/toolkit/query/react";
import { IApiResponse, IPaginate } from "../../interfaces/common";
import { IVitals } from "../../interfaces/vitals";
import { baseQuery } from "./common";

export const vitalsApi = createApi({
  reducerPath: "vitals",
  baseQuery: baseQuery,
  tagTypes: ["Vitals", "Vital", "VitalsByDate"],
  refetchOnFocus: true,
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    getVitalsForPatient: builder.query<
      IApiResponse<IPaginate<IVitals[]>>,
      { pagination: { page: number; limit: number }; id: string }
    >({
      query: ({ id, pagination: { page = 1, limit = 10 } }) =>
        `/vitals/patient/${id}?page=${page}&limit=${limit}`,
      providesTags: [{ type: "Vitals", id: "LIST" }],
    }),
    getVitalsByDate: builder.query<
      IApiResponse<IPaginate<IVitals[]>>,
      { id: string; date: string; pagination: { page: number; limit: number } }
    >({
      query: ({ id, date, pagination: { page = 1, limit = 10 } }) =>
        `/vitals/patient/${id}/date/${date}?page=${page}&limit=${limit}`,
      providesTags: (result, error, arg) => [
        { type: "VitalsByDate", id: `${arg.id}-${arg.date}` },
      ],
    }),
    getVital: builder.query<IApiResponse<IPaginate<IVitals[]>>, string>({
      query: (id) => `/vitals/${id}`,
      providesTags: (result, error, arg) => [{ type: "Vital", id: arg }],
    }),
    addVitals: builder.mutation<IVitals, Partial<IVitals>>({
      query: (body) => ({
        url: `vitals`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Vitals", "VitalsByDate"],
    }),
    updateVitals: builder.mutation<IVitals, Partial<IVitals>>({
      query(data) {
        return {
          url: `vitals/${data.id}`,
          method: "PATCH",
          body: data,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Vital", id: arg.id },
        "Vitals",
        "VitalsByDate",
      ],
    }),
  }),
});

export const {
  useGetVitalsForPatientQuery,
  useLazyGetVitalsByDateQuery,
  useGetVitalsByDateQuery,
  useAddVitalsMutation,
  useUpdateVitalsMutation,
  usePrefetch,
} = vitalsApi;
