import { Col, Row } from "react-bootstrap";

export interface IAppointmentCard {
  name: string;
  count: number;
  bgColor: string;
}

const BoxCard: React.FC<any> = ({ data }) => (
  <Row
    className="shadow border rounded-3 mx-1 p-2"
    style={{
      minWidth: "13vw",
      backgroundColor: data?.bgColor,
    }}
  >
    <Col className="d-flex flex-column">
      <p className="fw-bold m-0 text-light" style={{ fontSize: "0.9rem" }}>
        {data?.count}
      </p>
      <p className="mb-2 fw-bold text-light">{data?.name}</p>
    </Col>
  </Row>
);

const AppointmentCard: React.FC<IAppointmentCard> = (props) => {
  return (
    <Col key={props.name}>
      <BoxCard data={props} />
    </Col>
  );
};

export default AppointmentCard;
