import { useEffect, useState } from "react";
import {
  countriesWithStates,
  CountryWithState,
} from "../data/CountryAndStateData";

interface InputpickerProp {
  label: string;
  value: string;
}

interface ICountriesAndStates {
  selectedCountry: string | undefined;
  setSelectedCountry: React.Dispatch<React.SetStateAction<string | undefined>>;
  selectedCountryStates: InputpickerProp[] | undefined;
  selectedCountryStatesForMultiple: { [key: number]: InputpickerProp[] };
  setMultipleStates: (index: number, value: string) => void;
}

const useCountriesAndStatesCustomHook = (): ICountriesAndStates => {
  const [selectedCountry, setSelectedCountry] = useState<string | undefined>();
  const [selectedCountryStates, setSelectedCountryStates] = useState<
    InputpickerProp[]
  >([]);
  const [
    selectedCountryStatesForMultiple,
    setSelectedCountryStatesForMultiple,
  ] = useState<{ [key: number]: InputpickerProp[] }>({ 0: [] });

  const setMultipleStates = (index: number, value: string) => {
    let stateArray: InputpickerProp[] = [];

    countriesWithStates.forEach((countryObject: CountryWithState) => {
      if (countryObject.country === value) {
        countryObject.states.map((state: string) => {
          stateArray.push({
            label: state,
            value: state,
          });
        });
      }
    });

    setSelectedCountryStatesForMultiple((prev) => ({
      ...prev,
      [index]: stateArray,
    }));
  };
  useEffect(() => {
    let stateArray: InputpickerProp[] = [];

    selectedCountry &&
      countriesWithStates.forEach((countryObject: CountryWithState) => {
        if (countryObject.country === selectedCountry) {
          countryObject.states.map((state: string) => {
            stateArray.push({
              label: state,
              value: state,
            });
          });
        }
      });

    setSelectedCountryStates(stateArray);
  }, [selectedCountry]);

  return {
    selectedCountry,
    setSelectedCountry,
    selectedCountryStates,
    selectedCountryStatesForMultiple,
    setMultipleStates,
  };
};

export default useCountriesAndStatesCustomHook;
