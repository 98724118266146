import React from "react";
import { TopNav, SuperAdminLeftSideNav } from ".";

// const DRAWERWIDTH = "240px";

// const sideNav = {
//   width: DRAWERWIDTH,
// };


const Layout: React.FC<{children:React.ReactNode}> = ({children})=> {
  return (
    <div className=" row g-0">
      <div className="col-md-3 col-lg-2 p-0 ">
        <SuperAdminLeftSideNav />
      </div>

      <main role="main" className="col">
        <TopNav toggleSideMenu/>
        <div className="px-2 py-2 bg-light">{children}</div>
      </main>
      {/* <RightSideBar /> */}
    </div>
  );
};

export default Layout;
