import { createApi } from "@reduxjs/toolkit/query/react";
import { ICategory } from "../../interfaces/brandsCateMed";
import { IApiResponse, IPaginate } from "../../interfaces/common";
import { baseQuery } from "./common";

export const categoryApi = createApi({
  reducerPath: "category",
  baseQuery: baseQuery,
  tagTypes: ["Categories", "Category"],
  refetchOnFocus: true,
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    getCategory: builder.query<
      IApiResponse<IPaginate<ICategory[]>>,
      { pagination: { page: number; limit: number } }
    >({
      query: ({ pagination: { page = 1, limit = 10 } }) =>
        `medication-category?page=${page}&limit=${limit}`,
      providesTags: [{ type: "Category", id: "LIST" }],
    }),
    getCategoryById: builder.query<IApiResponse<ICategory>, string>({
      query: (id) => `/medication-category/${id}`,
      providesTags: (result, error, arg) => [{ type: "Category", id: arg }],
    }),
    addCategory: builder.mutation<ICategory, Partial<ICategory>>({
      query: (body) => ({
        url: `medication-category`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Categories", "Category"],
    }),
    updateCategory: builder.mutation<ICategory, Partial<ICategory>>({
      query(data) {
        return {
          url: `medication-category/${data.id}`,
          method: "PATCH",
          body: data,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Category", id: arg.id },
        "Category",
      ],
    }),
    deleteCategory: builder.mutation<ICategory, Partial<ICategory>>({
      query: ({ id }) => ({
        url: `medication-category/${id}`,
        method: "DELETE",
        body: id,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Category", id: arg.id },
        "Category",
      ],
    }),
  }),
});

export const {
  useGetCategoryQuery,
  useAddCategoryMutation,
  useGetCategoryByIdQuery,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
} = categoryApi;

