import { createApi } from "@reduxjs/toolkit/query/react";
import { IApiResponse, IPaginate } from "../../interfaces/common";
import { IPrescription } from "../../interfaces/prescriptions";
import { baseQuery } from "./common";

export const prescriptionsApi = createApi({
  reducerPath: "prescriptions",
  baseQuery: baseQuery,
  tagTypes: ["AllPrescriptions", "Prescriptions", "Prescription"],
  refetchOnFocus: true,
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    getPrescriptions: builder.query<IApiResponse<IPaginate<IPrescription[]>>, void>({
      query: () => "/prescriptions",
      providesTags: [{ type: "AllPrescriptions", id: "LIST" }],
    }),
    getPrescriptionsForPatient: builder.query<IApiResponse<IPaginate<IPrescription[]>>, string>({
      query: (id) => `/prescriptions/patient/${id}`,
      providesTags: [{ type: "Prescriptions", id: "LIST" }],
    }),
    addPrescription: builder.mutation<IPrescription, Partial<IPrescription>>({
      query: (body) => ({
        url: `prescriptions`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["AllPrescriptions", "Prescriptions", "Prescription"],
    }),
    updatePrescription: builder.mutation<IPrescription, Partial<IPrescription>>({
      query(data) {
        return {
          url: `prescriptions/${data.id}`,
          method: "PATCH",
          body: data,
        };
      },
      invalidatesTags: (result, error, arg) => [{ type: "Prescription", id: arg.id }, "Prescriptions"],
    }),
  }),
});

export const {
  useGetPrescriptionsQuery,
  useGetPrescriptionsForPatientQuery,
  useAddPrescriptionMutation,
  useUpdatePrescriptionMutation,
} = prescriptionsApi;
