import React, { ElementType } from "react";
import { Field, FieldProps, FieldAttributes } from "formik";
import { FloatingLabel, InputGroup } from "react-bootstrap";
import { Form, Input as RInput, InputPicker as RInputPicker, InputNumber as RInputNumber} from "rsuite";
import { DatePicker as RDatePicker } from "rsuite";
import { capitalizeFirstLetter } from "../../../helpers";

interface IBaseInput {
  name: string;
  label?: string;
}

interface IInput extends IBaseInput {
  type?: string;
  as?: ElementType;
  optn?: boolean;
  disableValidation?: boolean;
  opnValues?: string[];
  position?:any;
  DontshowError?:boolean
  notRroundedBorder?:boolean
  [key: string]: string | any;
}

interface IInputPicker extends IBaseInput {
  data: { label: string; value: string }[];
  searchable?: boolean;
}

interface IInuptAddon extends IInput {
  AddonText: string;
}

export const Input = ({ name, disable, type, label,position,DontshowError,notRroundedBorder,...props }: IInput) => {
  return (
    <>
      <Field disabled={disable} name={name}>
        {({ field: { onChange, ...field }, meta }: FieldProps) => (
          <>
            <Form.ControlLabel>{ label } </Form.ControlLabel>
              {!DontshowError? meta.touched && meta.error && (
              <Form.ErrorMessage style={{marginLeft:position?'10rem':''}} placement={"bottomStart"} show>
                {meta.error}
              </Form.ErrorMessage>
            )
            :
            ''
          }

            
            <RInput disabled={disable} style={{borderRadius:notRroundedBorder?'':'15px',borderColor:'grey'}} type={type || `text`} size="lg" onChange={(v, e) => onChange(e)} {...field} {...props} />            
          </>
        )}
      </Field>
    </>
  );
};



// : IInputPicker
export const InputPicker = ({ name, label, data, ...props }: any) => {
  return (
    <>
      <Field name={name}>
        {({ field: { onChange, ...field }, meta }: FieldProps) => (
          <>
            <Form.ControlLabel>{label || capitalizeFirstLetter(name)} </Form.ControlLabel>
            <Form.HelpText tooltip>{label || capitalizeFirstLetter(name)} is required</Form.HelpText>
            {meta.touched && meta.value && meta.error && (
              <Form.ErrorMessage placement="bottomStart" show>
                {meta.error}
              </Form.ErrorMessage>
            )}
            <br />
            <RInputPicker
              style={{borderRadius:'15px',borderColor:'grey'}}
              block
              size="lg"
              labelKey="label"
              valueKey="value"
              data={data}
              onChange={(v, e) => onChange({ ...e, target: { ...e.target, id: name, name: name, value: v } })}
              {...field}
              {...props}
            />
          </>
        )}
      </Field>
    </>
  );
};




export const InputAddon = ({ name, type, label, disableValidation, optn, opnValues, AddonText, ...props }: IInuptAddon) => {
  return (
    <>
      <Field name={name}>
        {({ field, meta }: any) => (
          <>
            <InputGroup className="mb-2">
              <InputGroup.Text>{AddonText}</InputGroup.Text>
            </InputGroup>
            {meta.touched && meta.error && <span className="text-danger">{meta.error}</span>}
          </>
        )}
      </Field>
    </>
  );
};
