import { createApi } from "@reduxjs/toolkit/query/react";
import { IApiResponse, IPaginate } from "../../interfaces/common";
import { ICurrency } from "../../interfaces/currency";
import { baseQuery } from "./common";

export const currencyApi = createApi({
  reducerPath: "currencies",
  baseQuery: baseQuery,
  tagTypes: ["Currencies", "Currency"],
  refetchOnFocus: true,
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    getCurrency: builder.query<IApiResponse<ICurrency>, void>({
      query: () => "/currency",
      providesTags: ['Currencies'],
    //   ['Currencies'],
    }),
    addCurrency: builder.mutation<ICurrency, ICurrency>({
        query: (body) => ({
          url: `currency`,
          method: "POST",
          body,
        }),
        invalidatesTags: ["Currencies"],
      }),
    updateCurrency: builder.mutation<ICurrency, Partial<ICurrency>>({
      query(data){
        return {
          url: `currency/${data.id}`,
          method: "PATCH",
          body: data,
        };
      },
      invalidatesTags: ["Currencies"]
    }),
  }),
});

export const {
useGetCurrencyQuery,
useAddCurrencyMutation,
useUpdateCurrencyMutation
}= currencyApi