import { createApi } from "@reduxjs/toolkit/query/react";
import { ILabCategory } from "../../../interfaces/lab";
import { IApiResponse, IPaginate, NoPaginate } from "../../../interfaces/common";
import { baseQuery } from "../common";
import axios from "axios";

export const testCategoryApi = createApi({
  reducerPath: "labTestCategory",
  baseQuery: baseQuery,
  tagTypes: ["TestCategories", "TestCategory"],
  refetchOnFocus: true,
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    getTestCat: builder.query<IApiResponse<IPaginate<ILabCategory[]>>, {pagination: { page: number; limit: number } }>({
      query: ({pagination:{ page = 1, limit = 10 } }) => `/laboratory/test/category?page=${page}&limit=${limit}`,
      providesTags: [{ type: "TestCategories", id: "LIST" }],
    }),
    getAllTestCat: builder.query<IApiResponse<NoPaginate<ILabCategory[]>>, void>({
      query: () => "/laboratory/test/category?limit=All",
      providesTags: [{ type: "TestCategories", id: "LIST" }],
    }),
    getTestCatForAnId: builder.query<IApiResponse<ILabCategory>, string>({
      query: (id) => `/laboratory/test/category/${id}`,
      providesTags: (result, error, arg) => [{ type: "TestCategory", id: arg }],
    }),
    addTestCat: builder.mutation<ILabCategory, Partial<ILabCategory>>({
        query: (body) => ({
          url: `/laboratory/test/category`,
          method: "POST",
          body,
        }),
        invalidatesTags: ["TestCategories", "TestCategory"],
      }),
      updateTestCat: builder.mutation<ILabCategory, Partial<ILabCategory>>({
        query(data){
          return {
            url: `/laboratory/test/category/${data.id}`,
            method: "PATCH",
            body: data,
          };
        },
        invalidatesTags: (result, error, arg) => [{ type: "TestCategory", id: arg.id },'TestCategories'],
      }),
      deleteTestCat: builder.mutation<ILabCategory,Partial<ILabCategory>>({
        query:({id}) => ({
          url: `/laboratory/test/category/${id}`,
          method: "DELETE",
          body: id,
        }),
        invalidatesTags: ["TestCategories", "TestCategory"]
    })
  }),
});

export const {
useGetTestCatQuery,
useGetAllTestCatQuery,
useAddTestCatMutation,
useUpdateTestCatMutation,
useDeleteTestCatMutation
} = testCategoryApi