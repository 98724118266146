import React, { useEffect, useState } from "react";
import { FormGroup, FormControl, FormSelect } from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";
import Classes from "./authstyle.module.css";
import FlexButton from "../../../components/elements/FlexButton";
import { Input } from "../../../components/elements/Form/borderInput";
import {
  Input as TInput,
  InputPickerCustom,
} from "../../../components/elements/Form";
import * as yup from "yup";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import InputFields from "../../../components/elements/FlexInput/input";
import { Form } from "react-bootstrap";
import { dialCodes } from "./reqAuth";
import { toast } from "react-toastify";
import { InputGroup, InputPicker, Input as Inpt } from "rsuite";
import { ErrorMessage, Field } from "formik";
import axios from "axios";
import {
  useGetCountriesQuery,
  useGetStatesForCountryQuery,
} from "../../../app/services/countries";
import useCountriesAndStatesCustomHook from "../../../utils/countriesAndStatesData";
import { countriesWithStates } from "../../../data/CountryAndStateData";

interface IAdmin {
  values: { [key: string]: string };
  adminLoading: boolean;
}

const AdminReg = ({ values, adminLoading }: IAdmin) => {
  const [options, setOptions] = useState([{}]);
  const history = useHistory();

  const [dial, setDial] = useState([{ nameDial: "", dial_code: "" }]);
  const [country, setCountry] = useState<any>([{ name: "" }]);
  const [countryValue, setCountryValue] = useState<string>("");
  const [state, setState] = useState<any>({});

  const [names, setNames] = useState<any>(0);
  const [namelist, setNamelist] = useState("");

  const { data } = useGetCountriesQuery();
  const { data: stateData } = useGetStatesForCountryQuery({
    country: countryValue,
  });

  const { setSelectedCountry, selectedCountryStates } =
    useCountriesAndStatesCustomHook();

  useEffect(() => {
    setCountry(data?.data);
  }, [data]);

  useEffect(() => {
    setState(stateData?.data.states);
  }, [stateData]);

  const dialCodes = async () => {
    try {
      const res: any = await axios.get(
        "https://countriesnow.space/api/v0.1/countries/codes",
        {
          headers: {
            "content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        },
      );
      let data = res.data?.data.map((e: any, index: number) => {
        return { ...e, nameDial: `${e.name} ${e.dial_code}` };
      });
      setDial(data);
    } catch (error: any) {
      return error.response;
    }
  };

  useEffect(() => {
    dialCodes();
  }, []);

  return (
    <div>
      <Form.Group>
        {/* <Field name="dialcode">
                    {({ field: { onChange,  ...field }, meta }: any) => (
                      <>
                <Inpt
                    placeholder="FirstName MiddleName LastName"
                    name="fullname"
                    className={Classes.inputs}
                    onChange={(v,e) => {
                      setNames(v?.split(' ')[1]?.length)
                      setNamelist(v)
                      onChange({
                        ...e,
                        target: {
                          ...e.target,
                          id: "fullname",
                          name: "fullname",
                          value: (names <=0) || (names == undefined)?'':namelist,
                        },
                      });
                     }}
                  />
                <small className="text-danger">
                  <ErrorMessage
                    name="fullname"
                    component="div"
                    className="field-error text-danger"
                  />
                </small>
              </>
              )}  
              </Field> */}
        <TInput
          placeholder="Firstname Middlename Lastname"
          name="fullname"
          withoutLabel={true}
          required={false}
        />
      </Form.Group>
      <Form.Group className="d-flex align-items-center justify-content-between">
        <div style={{ width: "30%" }}>
          <Field name="dialcode">
            {({ field: { onChange, ...field }, meta }: any) => (
              <>
                <InputPicker
                  size="lg"
                  name="dialcode"
                  placeholder="Dial Code"
                  data={dial || [{ nameDial: "", dial_code: "" }]}
                  valueKey="dial_code"
                  labelKey="nameDial"
                  className={`${Classes.inputs} mt-4`}
                  onChange={(v, e) => {
                    onChange({
                      ...e,
                      target: {
                        ...e.target,
                        id: "dialcode",
                        name: "dialcode",
                        value: v,
                      },
                    });
                  }}
                />
              </>
            )}
          </Field>
          <small className="text-danger">
            <ErrorMessage
              name="dialcode"
              component="div"
              className="field-error text-danger"
            />
          </small>
        </div>
        <div style={{ width: "60%" }}>
          <Input
            placeholder="Phone Number"
            name="phoneNumber"
            className={Classes.inputs}
            DontshowError
          />
          <small className="text-danger">
            <ErrorMessage
              name="phoneNumber"
              component="div"
              className="field-error text-danger"
            />
          </small>
        </div>
      </Form.Group>
      <Form.Group>
        <Input
          placeholder="Email Address"
          name="email"
          className={Classes.inputs}
          DontshowError
        />
        <small className="text-danger">
          <ErrorMessage
            name="email"
            component="div"
            className="field-error text-danger"
          />
        </small>
      </Form.Group>
      <Form.Group className="my-4">
        <Field name="country">
          {({ field: { onChange, ...field }, meta }: any) => (
            <>
              <InputPickerCustom
                withoutLabel={true}
                required={true}
                size="lg"
                name="country"
                data={countriesWithStates || []}
                placeholder="Country"
                labelKey="country"
                valueKey="country"
                className={Classes.inputs}
                onChange={(v, e) => {
                  setSelectedCountry(v);
                  onChange({
                    ...e,
                    target: {
                      ...e.target,
                      id: "country",
                      name: "country",
                      value: v,
                    },
                  });
                }}
              />
            </>
          )}
        </Field>
        <small className="text-danger">
          <ErrorMessage
            name="country"
            component="div"
            className="field-error text-danger"
          />
        </small>
      </Form.Group>
      <Form.Group>
        <Field name="state">
          {({ field: { onChange, ...field }, meta }: any) => (
            <>
              <InputPicker
                size="lg"
                name="state"
                placeholder="State"
                data={selectedCountryStates || []}
                labelKey="label"
                valueKey="value"
                className={Classes.inputs}
                onChange={(v, e) => {
                  onChange({
                    ...e,
                    target: {
                      ...e.target,
                      id: "state",
                      name: "state",
                      value: v,
                    },
                  });
                }}
              />
            </>
          )}
        </Field>
        <small className="text-danger">
          <ErrorMessage
            name="state"
            component="div"
            className="field-error text-danger"
          />
        </small>
      </Form.Group>
      <Form.Group>
        <Input
          placeholder="City"
          name="city"
          className={Classes.inputs}
          DontshowError
        />
        <small className="text-danger">
          <ErrorMessage
            name="city"
            component="div"
            className="field-error text-danger"
          />
        </small>
      </Form.Group>
      <Form.Group>
        <Input
          placeholder="Address"
          name="address"
          className={Classes.inputs}
          DontshowError
        />
        <small className="text-danger">
          <ErrorMessage
            name="address"
            component="div"
            className="field-error text-danger"
          />
        </small>
      </Form.Group>
      <Form.Group className="my-4">
        <Field name="gender">
          {({ field: { onChange, ...field }, meta }: any) => (
            <>
              <InputPicker
                size="lg"
                name="gender"
                data={[
                  { name: "Male", val: "male" },
                  { name: "Female", val: "female" },
                ]}
                labelKey="name"
                placeholder="Gender"
                valueKey="val"
                className={Classes.inputs}
                onChange={(v, e) => {
                  onChange({
                    ...e,
                    target: {
                      ...e.target,
                      id: "gender",
                      name: "gender",
                      value: v,
                    },
                  });
                }}
              />
            </>
          )}
        </Field>
        <small className="text-danger">
          <ErrorMessage
            name="gender"
            component="div"
            className="field-error text-danger"
          />
        </small>
      </Form.Group>

      <FlexButton
        color="flexblue"
        type="submit"
        style={{ padding: ".43rem 4rem", backgroundColor: "#0F4E71" }}
      >
        {adminLoading ? "Loading ..." : "Proceed"}
      </FlexButton>
    </div>
  );
};

export default AdminReg;
