import { createApi } from "@reduxjs/toolkit/query/react";
import { IApiResponse, IPaginate } from "../../interfaces/common";
import { ISpecialization } from "../../interfaces/specialization";
import { BloodPrice } from "../../interfaces/blood";
import { baseQuery } from "./common";

export const bloodmanagementApi = createApi({
  reducerPath: "bloodmanagement",
  baseQuery: baseQuery,
  tagTypes: ["BloodManagement"],
  refetchOnFocus: true,
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    getPriceHistory: builder.query<IApiResponse<IPaginate<BloodPrice[]>>,{ pagination: { page: number; limit: number } }
    >({
      query: ({ pagination: { page = 1, limit = 10 } }) => `/specialization?page=${page}&limit=${limit}`,
      providesTags: ["BloodManagement"],
    }),
    getSpecializationsSearch: builder.query<IApiResponse<IPaginate<ISpecialization[]>>, void>({
      query: () => `/specialization?limit=${1000}`,
      providesTags: ["BloodManagement"],
    }),
    getSpecializationDefault: builder.query<IApiResponse<ISpecialization[]>, void>({
      query: () => `/specialization/default`,
      providesTags: ["BloodManagement"],
    }),
    addSpecialization: builder.mutation<ISpecialization, Partial<ISpecialization>>({
      query: (body) => ({
        url: `specialization`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["BloodManagement"],
    }),
    updateSpecialization: builder.mutation<ISpecialization, Partial<ISpecialization>>({
      query(data) {
        return {
          url: `specialization/${data.id}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: ["BloodManagement"],
    }),
    getSpecializationById: builder.query<IApiResponse<ISpecialization>, string>({
      query: (id) => `specialization/${id}`,
      providesTags: (result, error, arg) => [{ type: "BloodManagement", id: arg }],
    }),
  }),
});

export const { useGetPriceHistoryQuery,useGetSpecializationDefaultQuery ,useAddSpecializationMutation, useUpdateSpecializationMutation, useGetSpecializationByIdQuery } = bloodmanagementApi;
