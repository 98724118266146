import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface patientBloodManagementPriceNotSetState {
  hasPrice: boolean;
}

const initialState: patientBloodManagementPriceNotSetState = {
  hasPrice: false,
};

const patientBloodManagementPriceNotSetSlice = createSlice({
  name: "patientBloodManagementHasPrice",
  initialState,
  reducers: {
    setPatientBloodManagementHasPrice: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.hasPrice = action.payload;
    },
  },
});

export const { setPatientBloodManagementHasPrice } =
  patientBloodManagementPriceNotSetSlice.actions;

export default patientBloodManagementPriceNotSetSlice.reducer;
