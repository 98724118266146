import { createApi } from "@reduxjs/toolkit/query/react";
import { IApiResponse, IPaginate } from "../../interfaces/common";
import { IModule } from "../../interfaces/modules";
import { baseQuery } from "./common";

export const modulesApi = createApi({
  reducerPath: "modules",
  baseQuery: baseQuery,
  tagTypes: ["Modules"],
  refetchOnFocus: true,
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    getModules: builder.query<IApiResponse<IModule[]>, void>({
      query: () => `/modules/permissions/`,
      providesTags: ["Modules"],
    }),
  }),
});

export const { useGetModulesQuery } = modulesApi;
