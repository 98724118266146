import React, { useEffect, useState } from "react";
import { FormGroup, FormControl, FormSelect } from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";
import Classes from "./authstyle.module.css";
import InputFields from "../../../components/elements/FlexInput/input";
import { Form } from "react-bootstrap";
import { dialCodes } from "./reqAuth";
import PasswordChecklist from "react-password-checklist";
import { Input, InputGroup } from "rsuite";
import EyeIcon from "@rsuite/icons/legacy/Eye";
import EyeSlashIcon from "@rsuite/icons/legacy/EyeSlash";

interface ICreatePassword {
  setPassword: (v: string) => void;
  setPasswordAgain: (v: string) => void;
  passwordValue: string;
  passwordAgain: string;
  setIsChecked: (any) => void;
  submitAllform: () => void;
  setPassValidation: (v: boolean) => void;
  isChecked: boolean;
  passValidation: boolean;
  submitLoading:boolean
}

const CreatePassword = ({
  setPassword,
  setPasswordAgain,
  setIsChecked,
  setPassValidation,
  passValidation,
  passwordValue,
  passwordAgain,
  isChecked,
  submitLoading,
  submitAllform,
}: ICreatePassword) => {
  const [visible, setVisible] = React.useState(false);

  const handleChange = () => {
    setVisible(!visible);
  };

  const [touched, setTouched] = useState(false);

  return (
    <div className="">
      <InputGroup style={{ borderRadius: "25px" }} className="mb-3" inside>
        <Input
          placeholder="Enter new password"
          className={Classes.Control}
          onChange={(v: any) => {
            setPassword(v);
          }}
          type={visible ? "text" : "password"}
        />
        <InputGroup.Button onClick={handleChange}>
          {visible ? <EyeIcon /> : <EyeSlashIcon />}
        </InputGroup.Button>
      </InputGroup>
      <InputGroup className="mb-3" style={{ borderRadius: "25px" }} inside>
        <Input
          className={Classes.Control}
          onChange={(v: string) => {
            setPasswordAgain(v);
          }}
          placeholder="Confirm password"
          type={visible ? "text" : "password"}
        />
        <InputGroup.Button onClick={handleChange}>
          {visible ? <EyeIcon /> : <EyeSlashIcon />}
        </InputGroup.Button>
      </InputGroup>

      <PasswordChecklist
        rules={[
          "minLength",
          "specialChar",
          "number",
          "capital",
          "lowercase",
          "match",
        ]}
        minLength={8}
        value={passwordValue}
        valueAgain={passwordAgain}
        onChange={(isValid) => {
          setPassValidation(isValid);
        }}
        iconSize={13}
        messages={{
          minLength: "Password has minimum of 8 characters",
          specialChar: "Password has at least a special character",
          number: "Password has at least a number",
          capital: "Password has at least a capital letter",
          lowercase: "Password has at least a lowercase letter",
          match: "Passwords match",
        }}
        style={{fontSize:"13px"}}
      />

      <div className="mb-5 p-3">
        <div className="">
          <Form.Check
            type="checkbox"
            value={1}
            onClick={(e: { [key: string]: any }) => {
              setTouched(true);
              setIsChecked(e?.target?.checked);
            }}
          />
          <div className="ml-3" style={{ cursor: "pointer" }}>
            By clicking this, you agree to our{" "}
            <a href="https://www.civishealth.com/terms_use/" target="_blank">
              Terms & Conditions
            </a>{" "}
            and {" "}
            <a href="https://www.civishealth.com/policy/" target="_blank">
              Privacy policy
            </a>
            {/* <Link to={{ pathname: 'https://flexipgroup.com/privacy-policy' }} target="_blank">
              Privacy policy
            </Link> */}
          </div>
        </div>
        {touched && !isChecked && (
          <small className="text-danger">
            Terms and condition must be checked
          </small>
        )}
      </div>
      <div className="text-center">
        <button
        className="text-white rounded px-5  py-1"
        disabled={passValidation && isChecked ? false : true}
        onClick={() => submitAllform()}
        type="submit"
        style={{ backgroundColor: "#0F4E71" }}
        >
          {submitLoading?'Loading ...':'Sign Up'}
        </button>
      </div>
      
    </div>
  );
};

export default CreatePassword;
