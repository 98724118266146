import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { inventoryState } from "../types";
import { RootState, AppThunk } from "../../app/store";
import axios from "axios";
const { REACT_APP_BASEURL } = process.env;

const initialState: inventoryState = {
    inputValue: {
    reserve: 0,
    quantity: 0,
    // expiryDate:new Date()
  },
  showDptModal: false

};


export const inventorySlice = createSlice({
  name: "inventory",
  initialState,
  reducers: {
    getValues: (state, action) => {
      return {
        ...state,
        inputValue: {
          reserve: action.payload.reserve,
          quantity: action.payload.quantity,
        //   expiryDate:action.payload.expiryDate
        },
      };
    },
    showModal: (state, action) => {
      return {
        ...state,
        showDptModal:action.payload.value
      }
    }
  }
});

export const { getValues,showModal } = inventorySlice.actions;

export const selectStateValues = (state: RootState) => state.inventory;

export default inventorySlice.reducer;

// export const { authStart } = userSlice.actions;
// export const userDetails = (state: RootState) => state.user;

// export default userSlice.reducer
