export interface Guide {
  name: string;
  path?: string;
  icon?: string;
}

const Guides: Array<Guide> = [
  {
    name: "Dashboard",
    path: "dashboard",
    icon: "bi bi-grid",
  },
  {
    name: "Hospitals",
    path: "hospitals",
    icon: "fas fa-hospital",
  },
  {
    name: "Billing and Collection",
    path: "billing",
    icon: "fas fa-money-bill",
  },
  {
    name: "My Profile",
    path: "profile",
    icon: "fas fa-user",
  },
];

export default Guides;
