import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { dependantProfilePage } from "../app/hospitalRedux/PatientSlice";
import { useAppDispatch } from "../app/hooks";
import PocketBase from "pocketbase";
import bellOn from '../assets/icons/bell-on.svg';
import bellOff from '../assets/icons/bell-off.svg';

import onIcon from '../assets/icons/onIcon.png';
import offIcon from '../assets/icons/offIcon.png';


import civisAlert from '../assets/audio/civisAlert.wav';
import styles from './notification.module.css'
import { Col, Card, ListGroup, Modal, Button, ButtonGroup } from "react-bootstrap";
import EditPassword from "../pages/HospitalAdmin/profile/editPassword";
import { loguserOut, timeoutFunc } from "../app/hospitalRedux/userSlice";
import api from "../controllers/endpoints/api";
import { useDispatch } from "react-redux";
import PasswordChangedSuccess from "./pChangeSuc";
import { toast } from "react-toastify";
import { fetchNotif, readNotif, readAllNotif, fetchUnreadNotif, fetchNotifUnder30Days }
  from "../controllers/notification/exppockt";
import moment from "moment";
import useSound from 'use-sound';
import { useGetNotificationsQuery } from "../app/services/notification";



const TopNav = ({ toggleSideMenu }) => {

  const pb = new PocketBase('https://pocketbase.flexipgroup.com');

  const [editPass, setEditPass] = useState(false);
  const [passSucModal, setPassSucModal] = useState(false);

  const [changed, setChanged] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const token = localStorage.getItem('token') || '';
  const [refreshData, setRefreshData] = useState(false);

  const [det, setDet] = useState(false)
  const initialSeconds = 10
  const [countNow, setCountNow] = useState(false);


  const [oldPass, setOldPass] = useState("");
  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const [passValidation, setPassValidation] = useState(false);
  const [userPas, setUserPass] = useState({ oldPassword: '', newPassword: '' });

  const [notificationList, setNotificationList] = useState([]);

  const [isInvoiceNewPage, setIsInvoiceNewPage] = useState(false);

  const val = localStorage.getItem("hospital_info") == null ? "Siver Hill Hospital" : JSON.parse(localStorage.getItem("hospital_info"));
  const dispatch = useAppDispatch();

  const history = useHistory();

  const location = useLocation()

  const [latestNotif, setLatestNotif] = useState(false);
  const [showUnder30, setShowUnder30] = useState(false);
  const [showUnReadNotif, setShowUnReadNotif] = useState(false);
  const [countNotif, setCountNotif] = useState(0);

  const [muteAllSound, setMuteAllSound] = useState(false);
  const [playSound, setPlaySound] = useState(false);

  const {
    data: notificationGetData,
    isSuccess: notificationGetDataIsSuccess,
    isLoading: notificationGetDataIsLoading,
    isError: notificationGetDataIsError,
  } = useGetNotificationsQuery()

  const getUserNotificationsUnder30Days = () => {
    notificationGetDataIsError && notificationGetDataIsLoading && toast.error("Error fetching notifications")

    notificationGetDataIsSuccess && console.log("Notification: ", notificationGetData.items)
  }


  useEffect(() => {

  }, [notificationGetData])

  const handleExitSucModal = () => {
    setPassSucModal(false);
    handleLogout()
  }

  const handleGoBack = () => {
    if (location.pathname.includes("/hospital/billing/invoice/draft/")) {
      setIsInvoiceNewPage(true)
    } else {
      history.goBack()
      setTimeout(() => {
        dispatch(dependantProfilePage(true))
      }, 2000)
    }
  }


  const pushPass = () => {
    if (!editPass) {
      // console.log('none set')
      return
    } else {
      updateUserPass()
    }

  }

  const handleGetNotification = () => {
    setShowUnReadNotif(!showUnReadNotif);
    // setRefreshData(!refreshData);
  }

  const toggleNotifAlert = () => {
    return setPlaySound(playSound);

  }

  const timeAgo = (date) => {
    const seconds = Math.floor((new Date() - date) / 1000);
    const hours = Math.floor(seconds / 3600);
    const days = Math.floor(seconds / 86400);
    const weeks = (Math.floor(seconds / (86400 * 7)));


    if (seconds < 10) {
      return "now";
    } else if (seconds < 90) {
      return "about a minute ago";
    } else if (seconds < 3600) {
      return `${Math.floor(seconds / 60)}m`;
    } else if (seconds < 86400) {
      return `${hours}h`;
    } else if (days < 2) {
      return "yesterday";
    } else if (days < 7) {
      return `${days}d`;
    }
    else {
      return `${weeks}w`
    }
  }


  const getNotification = async () => {

    if (showUnReadNotif) {
      const result = await fetchUnreadNotif();
      setNotificationList(result?.items);
      setCountNotif(result?.items.length);
      // console.log(result?.items);
    } else {
      const result = await fetchNotif();
      setNotificationList(result?.items);
      setCountNotif(result?.items.length);
      // console.log(result?.items);
    }
  }

  const handleNotifListen = async () => {
    pb.collection('notification').subscribe('*', function (e) {
      setRefreshData(!refreshData);
    });

  }

  const handleReadNotification = async (note) => {
    if (!note?.readStatus) {
      const res = await readNotif(note);
      history.push(`/hospital${note?.path}`);
    }
    else {
      history.push(`/hospital${note?.path}`);
    }
  }


  const handleReadAllNotification = async () => {
    try {
      readAllNotif(notificationList);

    } catch (error) {
      console.log(error);
    }
  }


  useEffect(() => {
    handleNotifListen();
    getNotification();
    // getUserNotifications();
    pushPass();
    logOutOnRefresh();
    setPlaySound(!playSound);

  }, [refreshData, showUnReadNotif]);

  const logOutOnRefresh = () => {
    if (changed) {
      handleLogout()
    } else return;
  }

  const updateUserPass = async () => {
    const res = await api.post('auth/resetPassFromProfile', {
      oldPassword: oldPass,
      newPassword: password
    }, token)
    // console.log(res)
    if (res?.data?.success) {
      setEditPass(false);
      setChanged(true);
    } else if (res?.data?.statusCode === 400) {
      toast.error('Old password is wrong');
    }
  }

  const handleLogout = () => {
    dispatch(loguserOut());
    localStorage.clear();
    dispatch(timeoutFunc({ payload: false }))
    //  window.location.reload();
    history.push("/hospital/login");
  };

  const handleFormSubmit = () => {
    setUserPass({ oldPassword: oldPass, newPassword: password });
    setRefreshData(!refreshData);
  }


  return (
    <div className="bg-light">
      <header className="navbar navbar-primary d-flex  flex-nowrap  bg-light sticky-top  px-1 shadow">
        <Col className="d-flex gap-2 p-3">
          <button
            style={{ zIndex: 9 }}
            onClick={() => toggleSideMenu()}
            className="navbar-toggler ml-3 shadow-none text-primary position-relative"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#sidebarMenu"
            aria-controls="sidebarMenu"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="fas fa-align-left" />
          </button>

          <button onClick={() => handleGoBack()} className="btn btn-outline-secondary ms-3">
            Go back
          </button>
        </Col>

        <Col className="d-flex justify-content-end px-5">

          <div className="navbar-nav col-lg-4 col-4 text-right">
            {/* {playSound ? <audio src={civisAlert} autoPlay /> : ''} */}
            <div className="nav-item d-flex text-nowrap">

              <ul className="d-flex m-0 p-0" style={{ listStyle: 'none' }}>
                <li onClick={() => { history.push('/hospital/queue-management') }} className="text-primary px-3" style={{ cursor: "pointer" }}>
                  <i className="fa fa-users fa-1x" aria-hidden="true"></i>
                </li>
                <li className="text-primary px-3 ">
                  <i className='fas fa-phone-slash fa-1x'></i>
                </li>
                <li className="text-primary px-3 table-icon">
                  <div className="d-flex">
                    {notificationList.length < 1 ? <img src={bellOff} height='20em' /> : <img src={bellOn} height='20em' />

                    }
                    <p>{countNotif}</p>
                  </div>


                  <div className="content p-0 card border shadow  py-5 position-absolute"
                    style={{ minWidth: '30em', maxWidth: '30em' }}>
                    <div className={`${styles.section} p-2 text-light text-start w-75`}>
                      <h5>Notifications</h5>
                    </div>

                    <div className="d-flex px-1 gap-3 justify-content-start mt-3 w-100">
                      <div className="d-flex justify-content-start align-items-center p-0 m-0 gap-2 px-3">
                        {!showUnReadNotif ? <img
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleGetNotification()}
                          src={offIcon} height='10em' /> : <img
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleGetNotification()}
                          src={onIcon} height='10em' />}
                        <p
                          onClick={() => handleGetNotification()}
                          className="p-0 m-0" style={{ cursor: 'pointer' }}>Show only unread notifications
                        </p>
                      </div>

                      <div className="d-flex gap-2 justify-content-end">
                        <i
                          onClick={() => setMuteAllSound(!muteAllSound)}
                          className={`${muteAllSound ? 'bi bi-volume-up' : 'bi bi-volume-mute'}`}
                          style={{ cursor: 'pointer' }}
                        ></i>
                        <p
                          onClick={() => handleReadAllNotification()}
                          className="p-0 m-0 " style={{ cursor: 'pointer', textDecoration: 'underline' }}>Mark all as Read</p>
                      </div>
                    </div>

                    <div className="d-flex gap-3 flex-column px-3 text-start mt-4"
                      style={{ overflowY: 'scroll', maxHeight: '20em' }}>
                      {
                        notificationList.length < 1 ? <p className="text-center mt-4">No Notifications</p> :
                          notificationList.map((note, index) => (

                            <div
                              key={index}
                              onClick={() => handleReadNotification(note)}
                              className="w-100 shadow-sm py-1 px-3 w-100"
                              style={{ cursor: 'pointer' }}>
                              <div className="d-flex justify-content-between align-items-center">
                                <p className="p-0 m-0 text-capitalize"
                                  style={{ fontWeight: note?.readStatus ? 'normal' : 'bold' }}>
                                  {note.title}
                                </p>
                                <p className="p-0 m-0">
                                  {timeAgo(new Date(note?.created))}
                                </p>
                              </div>
                              <div className="d-flex w-100"
                                style={{ overflow: 'scroll', wordWrap: 'break-word' }}>
                                <p className="p-0 m-0 mt-2 text-dark"
                                  style={{ fontWeight: note?.readStatus ? 'normal' : 'bold' }}
                                >{note.content}</p>
                              </div>
                            </div>
                          ))
                      }
                    </div>


                  </div>
                </li>
              </ul>
              <i className="bi bi-grip-vertical"></i>
              <span className=" text-primary fw-bold d-none d-md-inline-block px-3" href="#">
                <p className="pb-0 mb-0 text-center text-capitalize">{val.name ? val.name : ''}</p>
                <p className="mt-0 pt-0 text-center">{val.civisHospitalId ? val.civisHospitalId : ''}</p>
              </span>

            </div>

          </div>

        </Col>

        <Col xs={2} className="d-flex justify-content-center">
          <div className="ml-5 d-flex align-items-center  justify-content-center">
            <div className="table-icon text-primary mt-2">
              {token == "" ? '' : <i className="bi bi-person-circle" style={{ fontSize: '1.5em' }}></i>}
              <div className="content p-2 card border shadow position-absolute mr-2">
                <Card className="rounded rounded-3 border-0 shadow-lg" style={{ minWidth: "10rem" }}>
                  {
                    <>
                      <ListGroup variant="flush">
                        <ListGroup.Item
                          onClick={
                            () => history.push('/hospital/profile')
                          }

                        >
                          Profile
                        </ListGroup.Item>
                      </ListGroup>

                      <ListGroup variant="flush">
                        <ListGroup.Item
                          onClick={() => {
                            setEditPass(true);
                            setCountNow(true);
                          }}
                        >
                          Change password
                        </ListGroup.Item>
                      </ListGroup>

                      <ListGroup variant="flush">
                        <ListGroup.Item
                          onClick={handleLogout}
                        >
                          Log out
                        </ListGroup.Item>
                      </ListGroup>
                    </>
                  }
                </Card>

              </div>
            </div>
          </div>
        </Col>


      </header>

      {changed ? <PasswordChangedSuccess showModal={changed} exitModal={() => handleExitSucModal()} /> : ''}

      <Modal style={{ zIndex: '999999999999999' }} show={editPass} centered onHide={() => {
        setEditPass(false)
        setPassword('')
        setPasswordAgain('')
      }} >
        <Modal.Header className="modal-header border-0" closeButton></Modal.Header>
        <Modal.Body className="text-center">
          <EditPassword
            setOldPass={setOldPass}
            oldPassVal={oldPass}
            setPassword={setPassword}
            setPasswordAgain={setPasswordAgain}
            passwordValue={password}
            passwordAgain={passwordAgain}
            passValidation={passValidation}
            setPassValidation={setPassValidation}
          />
          <Button
            variant="outline-primary rounded"
            className="mt-5 mb-2 px-5"
            disabled={passValidation == false || oldPass == ""}
            onClick={() => handleFormSubmit()}>
            Submit
          </Button>

        </Modal.Body>
      </Modal>

      <Modal
        show={isInvoiceNewPage}
        centered
        onHide={() => {
          setIsInvoiceNewPage(false);
        }}
      >
        <>
          <Modal.Header
            className="modal-header border-0  text-white"
            closeButton
          ></Modal.Header>
          <Modal.Body className="text-center mb-4">
            Are you sure you want to leave this page, your current changes may not be saved.
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center">
            <ButtonGroup>
              <Button variant="primary" className="mr-2" onClick={() => { history.goBack(); setIsInvoiceNewPage(false) }}>
                Go Back
              </Button>
              <Button variant="outline-primary" onClick={() => { setIsInvoiceNewPage(false) }}>
                Continue Editing
              </Button>
            </ButtonGroup>
          </Modal.Footer>
        </>
      </Modal>

    </div>
  );
};

export default TopNav;
