import { createApi } from "@reduxjs/toolkit/query/react";
import { IApiResponse, IPaginate } from "../../interfaces/common";
import { IDepartment } from "../../interfaces/department";
import { baseQuery } from "./common";

export const departmentApi = createApi({
  reducerPath: "departments",
  baseQuery: baseQuery,
  tagTypes: ["Departments", "Department"],
  refetchOnFocus: true,
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    addDepartment: builder.mutation<IDepartment, Partial<IDepartment>>({
      query: (body) => ({
        url: `departments`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Departments"],
    }),
    updateDepartment: builder.mutation<IDepartment, Partial<IDepartment>>({
      query(data) {
        return {
          url: `departments/${data.id}`,
          method: "PATCH",
          body: data,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Department", id: arg.id },
        "Departments",
      ],
    }),
    getDepartment: builder.query<
      IApiResponse<IPaginate<IDepartment[]>>,
      { pagination: { page: number; limit: number } }
    >({
      query: ({ pagination: { page = 1, limit = 10 } }) =>
        `/departments?page=${page}&limit=${limit}`,
      providesTags: [{ type: "Departments", id: "LIST" }],
    }),
    searchDepartment: builder.query<
      IApiResponse<IDepartment[]>,
      {
        searchString?: string;
        pagination: { page?: number; limit?: number };
      }
    >({
      query: ({ searchString, pagination: { page = 1, limit = 10 } }) =>
        `/departments/${searchString}/filter`,
      providesTags: [{ type: "Departments" }],
    }),
    deleteDepartment: builder.mutation<IDepartment, Partial<IDepartment>>({
      query: ({ id }) => ({
        url: `/departments/${id}`,
        method: "DELETE",
        body: id,
      }),
      invalidatesTags: ["Departments"],
    }),
  }),
});

export const {
  useAddDepartmentMutation,
  useUpdateDepartmentMutation,
  useGetDepartmentQuery,
  useSearchDepartmentQuery,
  useDeleteDepartmentMutation,
} = departmentApi;

