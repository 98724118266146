import axios from 'axios' 
const { REACT_APP_BASEURL } = process.env;

export const dialCodes = async () => {
  try{
    const res = await axios.get(
  "https://countriesnow.space/api/v0.1/countries/codes",
  {
    headers: {
      "content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  }
);
return res.data;
}catch(error:any){
  return error.response
}
}

export const getAllCountries = async () => {
  try{
      const res = await axios.get(
    "https://countriesnow.space/api/v0.1/countries/flag/images",
    {
      headers: {
        "content-type": "application/json",
        "Access-Control-Allow-Origin": "*"
      },
    }
  );
  return res.data;
  }catch(error:any){
    return error.response
  }
};


export const getAllStates = async (body: { country: string }) => {
  try{
      const res = await axios.post(
    "https://countriesnow.space/api/v0.1/countries/states",
    body,
    {
      headers: {
        "content-type": "application/json",
        "Access-Control-Allow-Origin": "*"
      },
    }
  );
  return res.data;
  }catch(error:any){
    return error.response
  }
};

export const getAllCities = async (body: {
  country: string;
  state: string;
}) => {
  try{
     const res = await axios.post(
    "https://countriesnow.space/api/v0.1/countries/state/cities",
    body,
    {
      headers: {
        "content-type": "application/json",
        "Access-Control-Allow-Origin": "*"
      },
    }
  );
  return res.data;
  }catch(error:any){
    return error.response
  }
 
};

export const getRc = async(body:any) => {
 try{
        const res:any = await axios.get(`${REACT_APP_BASEURL}hospitals/${body}`,{
        headers: {
          "content-type": "application/json",
          "Access-Control-Allow-Origin": "*"
        },
      }
    );
    // console.log(res.data?.payload);
    return res.data?.payload;
  } catch (error: any) {
    console.log(error.response);
    return error.response;
  }
};


export const specializationList = [{
  id:"123467",
  name:'Allergy and immunology'
},
{
  id:"89101190",
  name:"Anesthesiology"
},
// {
//   value:"Cardiology",
//   label:"Cardiology"
// },
// {
//   value:"Dentistry",
//   label:"Dentistry"
// },
// {
//   value:"Dermatology",
//   label:"Dermatology"
// },
// {
//   value:"Diagnostic radiology",
//   label:"Diagnostic radiology"
// },
// {
//   value:"Emergency medicine",
//   label:"Emergency medicine"
// },
// {
//   value:"Endocrinology",
//   label:"Endocrinology"
// },
// {
//   value:"Family Medicine",
//   label:"Family Medicine"
// },
// {
//   value:"Gastroenterology",
//   label:"Gastroenterology"
// },
// {
//   value:"General",
//   label:"General"
// },
// {
//   value:"Geriatric medicine",
//   label:"Geriatric medicine"
// },
// {
//   value:"Hemayology",
//   label:"Hemayology"
// },
// {
//   value:"Internal Medcine",
//   label:"Internal Medcine"
// },
// {
//   value:"Medical Genetics",
//   label:"Medical Genetics"
// },
// {
//   value:"Nephrology",
//   label:"Nephrology"
// },
// {
//   value:"Neurology",
//   label:"Neurology"
// },
// {
//   value:"Nuclear Medicine",
//   label:"Nuclear Medicine"
// },
// {
//   value:"Obstetrics and gynecology",
//   label:"Obstetrics and gynecology"
// },
// {
//   value:"Oncology",
//   label:"Oncology"
// },
// {
//   value:"Opthalmology",
//   label:"Opthalmology"
// },
// {
//   value:"Otolaryngology",
//   label:"Otolaryngology"
// },
// {
//   value:"Pathology",
//   label:"Pathology"
// },
// {
//   value:"Pediatrics",
//   label:"Pediatrics"
// },
// {
//   value:"Physical medicine and rehabilitation",
//   label:"Physical medicine and rehabilitation"
// },
// {
//   value:"Preventive Medicine",
//   label:"Preventive Medicine"
// },
// {
//   value:"Psychiatry",
//   label:"Psychiatry"
// },
// {
//   value:"Rheumatology",
//   label:"Rheumatology"
// },
// {
//   value:"Surgery",
//   label:"Surgery"
// },
// {
//   value:"Urology",
//   label:"Urology"
// }
]