import { createApi } from "@reduxjs/toolkit/query/react";
import { IApiResponse, IPaginate } from "../../interfaces/common";
import { IHmo } from "../../interfaces/hmo";
import { baseQuery } from "./common";

export const hmoApi = createApi({
  reducerPath: "hmos",
  baseQuery: baseQuery,
  tagTypes: ["Hmos", "Hmo", "Template"],
  refetchOnFocus: true,
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    getHmos: builder.query<
      IApiResponse<IPaginate<IHmo[]>>,
      { pagination: { page?: number; limit?: number } }
    >({
      query: ({ pagination: { page = 1, limit = 10 } }) =>
        `/hmo?page=${page}&limit=${limit}`,
      providesTags: [{ type: "Hmos", id: "LIST" }],
    }),

    getAllHmos: builder.query<
      IApiResponse<IPaginate<IHmo[]>>, void
    >({
      query: () =>
        `/hmo?limit=${"All"}`,
      providesTags: [{ type: "Hmos", id: "LIST" }],
    }),

    getHmo: builder.query<IApiResponse<IPaginate<IHmo[]>>, string>({
      query: (id) => `/hmo/${id}`,
      providesTags: (result, error, arg) => [{ type: "Hmo", id: arg }],
    }),
    getHmoExcelTemplate: builder.query<
      IApiResponse<{ url: string }>,
      "url" | "file"
    >({
      query: (type: "url" | "file" = "url") => `/hmo/template/?type=${type}`,
      providesTags: (result, error, arg) => [{ type: "Template", id: arg }],
    }),
    addHmo: builder.mutation<IHmo, Partial<IHmo>>({
      query: (body) => ({
        url: `hmo`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Hmos", "Hmo"],
    }),
    updateHmo: builder.mutation<IHmo, Partial<IHmo>>({
      query(data) {
        return {
          url: `hmo/${data.id}`,
          method: "PATCH",
          body: data,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Hmo", id: arg.id },
        "Hmos",
      ],
    }),
    deleteHmo: builder.mutation<IHmo, Partial<IHmo>>({
      query: ({ id }) => ({
        url: `/hmo/${id}`,
        method: "DELETE",
        body: id,
      }),
      invalidatesTags: ["Hmos", "Hmo"],
    }),
  }),
});

export const {
  useGetHmosQuery,
  useGetHmoExcelTemplateQuery,
  useGetHmoQuery,
  useAddHmoMutation,
  useUpdateHmoMutation,
  useDeleteHmoMutation,
  useGetAllHmosQuery
} = hmoApi;
