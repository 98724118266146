import {  createAsyncThunk,createSlice,PayloadAction } from "@reduxjs/toolkit";
import { patientReg } from '../types'
import { RootState, AppThunk } from '../store';
import axios from "axios";
const { REACT_APP_BASEURL} = process.env;


const initialState:patientReg = {
 personalDetails:null,
 patientAdd:null,
 isLoading:false,
 patientId:'',
 regError:'',
 editReason:{
  showModal:false,
  consultationId:'',
},
dependantNumber:'',
showDependantProfile:false
}

async function registerPatient(patientObj: {[key:string]:string | boolean}) {
  let token = localStorage.getItem("token");
  console.log(token)
    try {
      const response = await axios.post(`${REACT_APP_BASEURL}patients`, patientObj,{
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      });
      return response.data
    }catch (error:any) {
        return error.response.data
    }
}



export const patientRegAsync = createAsyncThunk(
    "patientReg/registerPatient",
    async (patientObj: {[key:string]:string | boolean} | any) => {
        const response = await registerPatient(patientObj);
        return response;
    }
);


export const patientRegSlice = createSlice({
    name: "patientReg",
    initialState,
    reducers: {
      personal:(state,action) => {
          return{
              ...state,
              personalDetails:action.payload
          }
      },
     clearId:(state) => {
        return{
            ...state,
            patientId:''
        }
    },
    editResonForVisit:(state,action) => {
      return {
        ...state,
        editReason:{
          showModal:action.payload.showModal,
          consultationId:action.payload.consultationId
        }
      }
    },
    numberOfDependant:(state,action) => {
      return {
        ...state,
        dependantNumber:action.payload
      }
    },
    dependantProfilePage:(state,action) => {
      return {
        ...state,
        showDependantProfile:action.payload
      }
    },
    },
    extraReducers: (builder) => {
        builder
        .addCase(patientRegAsync.pending, (state) => {
            state.isLoading = true;
          })
          .addCase(patientRegAsync.fulfilled, (state, action:any) => {
              console.log(action.payload.payload)
            if (action.payload?.payload) {
                return{
                    ...state,
                    isLoading:false,
                    patientId:action.payload.payload.id
                }
            }else{
              return{
                  ...state,
                  isLoading:false,
              }
              }
            })
            .addCase(patientRegAsync.rejected, (state,action:any) => {
              return{
                  ...state,
                  isLoading:false,
                  regError:'Network Error'
              }
            })
    }
  });


  export const { personal,clearId,editResonForVisit,numberOfDependant,dependantProfilePage} = patientRegSlice.actions;

  export const selectStateValues = (state: RootState) => state.patientReg;
  
  export default patientRegSlice.reducer;
  
