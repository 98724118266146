import React, { useEffect, useState } from "react";
import { FormGroup, FormControl, FormSelect } from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";
import Classes from "./authstyle.module.css";
import InputFields from "../../../components/elements/FlexInput/input";
import { Form } from "react-bootstrap";
import PasswordChecklist from "react-password-checklist";
import { Input, InputGroup } from "rsuite";
import EyeIcon from "@rsuite/icons/legacy/Eye";
import EyeSlashIcon from "@rsuite/icons/legacy/EyeSlash";

interface ICreatePassword {
  setOldPass: (v: string) => void;
  setPassword: (v: string) => void;
  setPasswordAgain: (v: string) => void;
  passwordValue: string;
  passwordAgain: string;
  oldPassword: string;
  passValidation: boolean;
  setPassValidation: (v: boolean) => void;
}

const CreatePassword = ({
  setOldPass,
  setPassword,
  setPasswordAgain,
  setPassValidation,
  passValidation,
  passwordValue,
  passwordAgain
}: ICreatePassword) => {
  const [visible, setVisible] = React.useState(false);
  const [cVisible, setCVisible] = React.useState(false);
  const [oVisible, setCOVisible] = React.useState(false);


  const initialSeconds = 10
  const [seconds, setSeconds ] =  useState(initialSeconds);

  const handleChange = () => {
    setVisible(!visible);
  };

  const toggleCVisible = () => {
    setCVisible(!cVisible)
  };

  const toggleShowOldP = () => {
    setCOVisible(!oVisible)
  };

  return (

    <div className="d-flex flex-column align-items-center">

      <InputGroup style={{ borderRadius: "25px", maxWidth: '20rem' }} className="mb-3" inside>
        <Input
          placeholder="Enter Old password"
          className={Classes.Control}
          onChange={(v: any) => {
            setOldPass(v);
          }}
          type={oVisible ? "text" : "password"}
        />
        <InputGroup.Button onClick={toggleShowOldP}>
          {oVisible ? <EyeIcon /> : <EyeSlashIcon />}
        </InputGroup.Button>
      </InputGroup>


      <InputGroup style={{ borderRadius: "25px", maxWidth: '20rem' }} className="mb-3" inside>
        <Input
          placeholder="Enter new password"
          className={Classes.Control}
          onChange={(v: any) => {
            setPassword(v);
          }}
          type={visible ? "text" : "password"}
        />
        <InputGroup.Button onClick={handleChange}>
          {visible ? <EyeIcon /> : <EyeSlashIcon />}
        </InputGroup.Button>
      </InputGroup>


      <InputGroup className="mb-3" style={{ borderRadius: "25px", maxWidth: '20rem' }} inside>
        <Input
          className={Classes.Control}
          onChange={(v: string) => {
            setPasswordAgain(v);
          }}
          placeholder="Confirm password"
          type={cVisible ? "text" : "password"}
        />
        <InputGroup.Button onClick={toggleCVisible}>
          {visible ? <EyeIcon /> : <EyeSlashIcon />}
        </InputGroup.Button>
      </InputGroup>

      <PasswordChecklist
        rules={[
          "minLength",
          "specialChar",
          "number",
          "capital",
          "lowercase",
          "match",
        ]}
        minLength={8}
        value={passwordValue}
        valueAgain={passwordAgain}
        onChange={(isValid) => {
          setPassValidation(isValid);
        }}
        iconSize={13}
        messages={{
          minLength: "Password has minimum of 8 characters",
          specialChar: "Password has at least a special character",
          number: "Password has at least a number",
          capital: "Password has at least a capital letter",
          lowercase: "Password has at least a lowercase letter",
          match: "Passwords match",
        }}
        style={{ fontSize: "13px" }}
      />
    </div>
  );
};

export default CreatePassword;
