import { createApi } from "@reduxjs/toolkit/query/react";
import { IApiResponse, IPaginate } from "../../interfaces/common";
import { IRole } from "../../interfaces/roles";
import { IPermission, IPermissionModuleJoined } from "../../interfaces/permissions";
import { baseQuery } from "./common";

export const rolesApi = createApi({
  reducerPath: "roles-permissions",
  baseQuery: baseQuery,
  tagTypes: ["Roles", "Role", "Permissions", "Permission", "PermissionsModules"],
  refetchOnFocus: true,
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    getRoles: builder.query<IApiResponse<IRole[]>, void>({
      query: () => `/roles/`,
      providesTags: ["Roles"],
    }),
    getRoleById: builder.query<IApiResponse<IRole>, string>({
      query: (id) => `/roles/${id}`,
      providesTags: (result, error, arg) => [{ type: "Role", id: arg }],
    }),
    addRole: builder.mutation<IRole, Partial<IRole>>({
      query: (body) => ({
        url: `roles`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Roles"],
    }),
    duplicateRole: builder.mutation<IRole, string>({
      query: (roleId) => ({
        url: `roles/duplicateRole/${roleId}`,
        method: "POST",
      }),
      invalidatesTags: ["Roles"],
    }),
    updateRole: builder.mutation<IRole, Partial<IRole>>({
      query(data) {
        return {
          url: `roles/${data.id}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: (result, error, arg) => [{ type: "Role", id: arg.id }, "Roles"],
    }),
    getPermissions: builder.query<IApiResponse<IPermission[]>, void>({
      query: () => `/permission/`,
      providesTags: ["Permissions"],
    }),
    getPermissionsAndModules: builder.query<IApiResponse<IPermissionModuleJoined[]>, void>({
      query: () => `/permission/fetch_module_permissions`,
      providesTags: ["PermissionsModules"],
    }),
    getPermissionById: builder.query<IApiResponse<IPermission>, string>({
      query: (id) => `/permission/${id}`,
      providesTags: (result, error, arg) => [{ type: "Permission", id: arg }],
    }),
    addPermission: builder.mutation<IPermission, Pick<IPermission, "hospitalId" | "moduleId" | "name" | "description">>({
      query: (body) => ({
        url: `permission`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Permission", "Permissions", "PermissionsModules", "Roles", "Role"],
    }),
    updatePermission: builder.mutation<IPermission, Pick<IPermission, "id" | "hospitalId" | "moduleId" | "name" | "description">>(
      {
        query(data) {
          return {
            url: `permission/${data.id}`,
            method: "PATCH",
            body: data,
          };
        },
        invalidatesTags: ["Permission", "Permissions", "PermissionsModules", "Roles", "Role"],
      }
    ),
    assignPermissionToRole: builder.mutation<{ message: string }, { roleId: string; permissionId: string[] }>({
      query(data) {
        return {
          url: `permission/assign_permission_to_role`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["Permission", "Permissions", "PermissionsModules", "Roles", "Role"],
    }),
    unassignPermissionFromRole: builder.mutation<{ message: string }, { roleId: string; permissionId: string }>({
      query(data) {
        return {
          url: `permission/unassign_permission_from_role`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["Permission", "Permissions", "PermissionsModules", "Roles", "Role"],
    }),
  }),
});

export const {
  useGetRolesQuery,
  useGetRoleByIdQuery,
  useAddRoleMutation,
  useDuplicateRoleMutation,
  useUpdateRoleMutation,
  useGetPermissionsQuery,
  useGetPermissionsAndModulesQuery,
  useGetPermissionByIdQuery,
  useAddPermissionMutation,
  useUpdatePermissionMutation,
  useAssignPermissionToRoleMutation,
  useUnassignPermissionFromRoleMutation,
} = rolesApi;
