import React from 'react'
import Classes from "./button.module.css"

interface spec extends React.ButtonHTMLAttributes<HTMLButtonElement> {
color?:string;
children:string;
borderwhite?:boolean;
styles?: string
}
const FlexButton:React.FC<spec> = (props:spec) => {
const style:any[] = [Classes.button]
style.push(props.styles)
    return (
        <div className="text-center">
            <button {...props} className={style.join(" ")} >
                <span className="mr-1" style={{color:props.color=='flexblue' ? '#ffff':'#0F4E71'}}>
                    {props.children}
                </span>
            </button>

        </div>
        
    )
}

export default FlexButton;