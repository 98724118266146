import { createApi } from "@reduxjs/toolkit/query/react";
import { IAppointmentType } from "../../interfaces/appointmentType";
import { IApiResponse, IPaginate } from "../../interfaces/common";
import { baseQuery } from "./common";

export const appointmentTypeApi = createApi({
    reducerPath: "appointmentType",
    baseQuery: baseQuery,
    tagTypes: ["AppointmentType"],
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
    endpoints: (builder) => ({
        getAppointmentType: builder.query<IApiResponse<IPaginate<IAppointmentType[]>>,void>({
            query: () => `/appointment-types?limit=100`,
            providesTags: [{ type: "AppointmentType", id: "LIST"}],
        }),
        addAppointmentType: builder.mutation<IAppointmentType, Partial<IAppointmentType>>({
            query: (body) => ({
                url: `/appointment-types`,
                method: "POST",
                body,
            }),
            invalidatesTags: ["AppointmentType"],
        }),
        updateAppointmentType: builder.mutation<IAppointmentType, Partial<IAppointmentType>>({
            query(data) {
                return {
                    url: `/appointment-type/${data.id}`,
                    method: "PATCH",
                    body: data,
                };
            },
            invalidatesTags: (result, error, arg) => [
                { type: "AppointmentType", id: arg.id }, "AppointmentType",
            ]
        })
    })
})

export const { useGetAppointmentTypeQuery, useAddAppointmentTypeMutation, useUpdateAppointmentTypeMutation } = appointmentTypeApi;