import { IInvoice } from "../../../interfaces/invoice";
import moment from "moment";
interface IBilledInvoiceTable {
  data: IInvoice;
  currency: string;
}

const BilledInvoiceTable: React.FC<IBilledInvoiceTable> = (props) => {
  return (
    <tr key={props.data.id}>
      <td>{props.data?.invoiceNumber}</td>
      <td>{new Date(props.data?.updatedAt || "").toDateString()}</td>
      <td>{props.data?.patientFullName}</td>
      <td>{`${props.currency} ${props.data?.amountPaid}`}</td>
    </tr>
  );
};

export default BilledInvoiceTable;
