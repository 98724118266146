import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Card } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import useHandleDashboardUserData from "../../../app/hospitalRedux/useHandleDashboardUserData";
import { IInvoice, IInvoicePayload } from "../../../interfaces/invoice";
import { CheckPermission } from "../Dashboard";
import BilledInvoiceCard from "./BilledInvoiceCard";
import BilledInvoiceTable from "./BilledInvoiceTable";
import DepartmentTable from "./DepartmentTable";
import LabAndRadCard from "./LabAndRadCard";
import LineOneComponent from "./LineOneComponent";
import UserCard from "./UserCard";
import crossLogo from "../../../assets/images/crosslogo.png";
import moment from "moment";
import AppointmentCard from "./AppointmentCard";
import { Progress } from "rsuite";

export type IDashboardUserType =
  | "Healthcare Admin Manager"
  | "Super Admin"
  | "Auditor"
  | "Accountant/Finance Manager"
  | "Admin Officer"
  | "Receptionist"
  | "Doctor"
  | "Nurse"
  | "Lab Technician"
  | "Lab Supervisor"
  | "Imaging Technician"
  | "Imaging Supervisor"
  | "Inventory Manager"
  | "Pharmacist";

interface INewDashboardUserView {
  userType: IDashboardUserType | null;
}

const NewDashboardUserView: React.FC<INewDashboardUserView> = (props) => {
  const history = useHistory();
  const { userType } = props;
  const {
    LineOneData,
    UsersCardData,
    DepartmentTableData,
    PaidInvoiceData,
    CurrencyCode,
    MedicationOrderData,
    CurrentDateMedication,
    BloodBankData,
    LabRequestData,
    RadRequestData,
    MedicationRequest,
    FilteredAppointment,
    InstockAndOutOfStockProducts,
  } = useHandleDashboardUserData(userType as IDashboardUserType);

  return (
    <React.Fragment>
      <Row>
        {LineOneData.map((value) => {
          if (value !== undefined) {
            return <LineOneComponent {...value} />;
          }
        })}
      </Row>

      {
        //   userType === "Accountant/Finance Manager" ||
        // userType === "Imaging Technician" ||
        // userType === "Imaging Supervisor" ||
        // userType === "Lab Supervisor" ||
        // userType === "Lab Technician" ||
        // userType === "Receptionist" ||
        // userType === "Inventory Manager" ||
        // userType?.includes("Pharmacist") ||
        // userType?.includes("Doctor") ||
        // userType?.includes("Nurse")
        !CheckPermission("View Department Corner") ||
        !CheckPermission("View Staff Corner") ? null : (
          <Row>
            <Card className="shadow-lg w-100 p-4 border-0 mt-4">
              <Card.Body className="d-flex gap-2">
                {
                  //     userType === "Super Admin" ||
                  // userType === "Healthcare Admin Manager" ||
                  // userType === "Auditor" ||
                  // userType === "Admin Officer"
                  CheckPermission("View Staff Corner") ? (
                    <Col xs={7}>
                      <Row>
                        {UsersCardData.map((value) => {
                          if (value !== undefined) {
                            return <UserCard {...value} />;
                          }
                        })}
                      </Row>
                      <div className="w-100 px-4 mt-4">
                        {CheckPermission("View Users") && (
                          <Button
                            onClick={() => history.push("settings/users")}
                          >
                            View all users
                          </Button>
                        )}
                      </div>
                    </Col>
                  ) : null
                }
                {
                  //     userType === "Super Admin" ||
                  // userType === "Healthcare Admin Manager" ||
                  // userType === "Auditor" ||
                  // userType === "Admin Officer"
                  CheckPermission("View Department Corner") ? (
                    <Col>
                      <Card>
                        <Card.Header className="bg-primary text-center text-light">
                          Departments
                        </Card.Header>
                        <Card.Body className="px-0 py-0">
                          <table className="table table-bordered">
                            <tbody>
                              {DepartmentTableData.map((value) => {
                                if (value !== undefined) {
                                  return <DepartmentTable {...value} />;
                                }
                              })}
                            </tbody>
                          </table>
                          <div className="px-3 text-end py-2">
                            {CheckPermission("View Departments") && (
                              <a href="settings/roles-and-permissions">
                                See More
                              </a>
                            )}
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  ) : null
                }
              </Card.Body>
            </Card>
          </Row>
        )
      }

      <Row className="mt-4">
        {
          //   userType === "Super Admin" ||
          // userType === "Healthcare Admin Manager" ||
          // userType === "Auditor" ||
          // userType === "Accountant/Finance Manager"
          CheckPermission("Billing Corner") ? (
            <Col xs={6}>
              <Card className="border border-1 p-2">
                <Card.Body className="px-0 py-3">
                  <p className="text-primary px-3 fw-bold">Account</p>
                  <div>
                    <BilledInvoiceCard
                      invoiceInfo={
                        PaidInvoiceData as unknown as IInvoicePayload
                      }
                      currency={CurrencyCode as string}
                    />
                    <p className="p-3 text-primary">
                      Paid Invoice only report data in the Last 30 days
                    </p>
                  </div>
                  {PaidInvoiceData?.InvoiceData &&
                  PaidInvoiceData?.InvoiceData?.length <= 0 ? (
                    <p className="w-100 text-center font-weight-bold">
                      No record found
                    </p>
                  ) : (
                    <>
                      <Card.Header className="bg-primary text-center text-light">
                        Billed Invoices
                      </Card.Header>

                      <table className="table table-bordered">
                        <tbody>
                          <tr>
                            <td>Invoice ID</td>
                            <td>Date</td>
                            <td>Patient Name</td>
                            <td>Amount</td>
                          </tr>
                          {PaidInvoiceData?.InvoiceData &&
                            PaidInvoiceData.InvoiceData.map((depts) => (
                              <BilledInvoiceTable
                                data={depts as IInvoice}
                                currency={CurrencyCode as string}
                              />
                            ))}
                        </tbody>
                      </table>
                    </>
                  )}
                  <div className="px-3 text-end py-2">
                    <Button onClick={() => history.push("/hospital/billing")}>
                      View all
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ) : null
        }
        {
          //   userType === "Super Admin" ||
          // userType === "Healthcare Admin Manager" ||
          // userType === "Auditor" ||
          // userType === "Admin Officer" ||
          // userType?.includes("Pharmacist")
          CheckPermission("Latest Medication Order") ? (
            <Col xs={6}>
              <Card className="border border-1 p-2">
                <Card.Header className="bg-primary text-center text-light">
                  Latest Medication Order
                </Card.Header>
                <Card.Body className="px-0 py-0" style={{ minWidth: "30em" }}>
                  {MedicationOrderData && MedicationOrderData.length <= 0 ? (
                    <p className="py-3 w-100 font-weight-bold text-center">
                      No record found
                    </p>
                  ) : (
                    <table className="table table-bordered">
                      <tbody>
                        {
                          <>
                            <tr>
                              <td>Order ID</td>
                              <td>Number of items</td>
                              <td>Total price</td>
                              <td>Status</td>
                            </tr>
                            {MedicationOrderData &&
                              MedicationOrderData.map((orders, index) => (
                                <tr key={index}>
                                  <td>{orders?.orderNumber}</td>
                                  <td>{orders?.requests?.length}</td>
                                  <td>{`${CurrencyCode || ""} ${orders?.totalPrice}`}</td>
                                  <td className="text-capitalize">
                                    {orders?.status}
                                  </td>
                                </tr>
                              ))}
                          </>
                        }
                      </tbody>
                    </table>
                  )}

                  <div className="px-3 text-end py-2">
                    <Button
                      onClick={() => history.push("/hospital/medications")}
                    >
                      View pending order
                    </Button>
                  </div>
                  <p>{`Medication Sales today ${new Date().toDateString()}`}</p>
                  <p>{`Total Item:  ${CurrentDateMedication?.length || 0}`}</p>
                  <p>{`Total Price: ${CurrencyCode || ""} ${CurrentDateMedication && CurrentDateMedication?.length > 1 ? CurrentDateMedication?.slice(0, 5).reduce((acc, value) => acc?.totalPrice + value?.totalPrice) : CurrentDateMedication && CurrentDateMedication.length > 0 ? CurrentDateMedication[0]?.totalPrice : 0}`}</p>
                </Card.Body>
              </Card>
            </Col>
          ) : null
        }
      </Row>

      <Row className="mt-2">
        {
          //   userType === "Super Admin" ||
          // userType === "Healthcare Admin Manager" ||
          // userType === "Auditor" ||
          // userType === "Admin Officer" ||
          // userType === "Lab Supervisor" ||
          // userType === "Lab Technician"
          CheckPermission("Blood Bank Corner") ? (
            <Col xs={5}>
              <Card className="p-3">
                <Card.Header className="bg-primary text-center text-light">
                  Blood Bank
                </Card.Header>
                <Card.Body className="px-0 py-0">
                  {BloodBankData.length <= 0 ? (
                    <p className="w-100 text-center">
                      No Blood bank data available
                    </p>
                  ) : (
                    <table className="table table-bordered">
                      <tbody>
                        {BloodBankData.map((depts, index) => (
                          <tr key={index}>
                            <td>{depts.name}</td>
                            <td>{depts.count}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                  <div className="px-5 d-flex justify-content-end">
                    <Button
                      onClick={() => history.push("/hospital/blood-management")}
                    >
                      Blood Bank
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ) : null
        }
        <Col xs={7}>
          {
            //   userType === "Super Admin" ||
            // userType === "Healthcare Admin Manager" ||
            // userType === "Auditor" ||
            // userType === "Admin Officer" ||
            // userType === "Lab Technician" ||
            // userType === "Lab Supervisor"
            CheckPermission("Lab Corner") ? (
              <Card className="p-2 mb-2">
                <Card>
                  <Card.Header
                    className="bg-primary text-center text-light"
                    style={{ fontWeight: "700" }}
                  >
                    Laboratory
                  </Card.Header>
                  <Card.Body className="d-flex flex-column gap-5 p-2">
                    <div className="d-flex gap-3">
                      {LabRequestData.map((infos, index) => (
                        <Col key={index}>
                          <LabAndRadCard {...infos} />
                        </Col>
                      ))}
                    </div>
                    <div className="px-5 d-flex justify-content-end">
                      <Button onClick={() => history.push("/hospital/labs")}>
                        View all
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
              </Card>
            ) : null
          }

          {
            //   userType === "Super Admin" ||
            // userType === "Healthcare Admin Manager" ||
            // userType === "Auditor" ||
            // userType === "Admin Officer" ||
            // userType === "Imaging Supervisor" ||
            // userType === "Imaging Technician"
            CheckPermission("Radiology Corner") ? (
              <Card className="p-2 mb-2">
                <Card>
                  <Card.Header
                    className="bg-primary text-center text-light"
                    style={{ fontWeight: "700" }}
                  >
                    Radiology
                  </Card.Header>
                  <Card.Body className="d-flex flex-column gap-5 p-2">
                    <div className="d-flex gap-3">
                      {RadRequestData.map((infos, index) => (
                        <Col key={index}>
                          <LabAndRadCard {...infos} />
                        </Col>
                      ))}
                    </div>
                    <div className="px-5 d-flex justify-content-end">
                      <Button onClick={() => history.push("/hospital/labs")}>
                        View all
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
              </Card>
            ) : null
          }
        </Col>
      </Row>

      <Row className="mt-2">
        {
          // userType?.includes("Pharmacist")
          CheckPermission("Medication Request Corner") ? (
            <Col xs={6}>
              <Card className="p-2">
                <div className="w-100 d-flex justify-content-between align-items-center gap-2 px-3 py-2">
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <img src={crossLogo} height="50em" alt="cross logo" />
                    <p className="fw-bold p-0 m-0">Medication</p>
                  </div>
                  <div>
                    <div>
                      <p className="text-primary p-0 m-0 fw-bold">{`Medication Sales today ${new Date().toDateString()}`}</p>
                    </div>
                    <div className="d-flex mt-2 w-100 justify-content-between align-items-center">
                      <p>{`Total Item:  ${CurrentDateMedication?.length || 0}`}</p>
                      <p>{`Total Price: ${CurrencyCode || ""} ${CurrentDateMedication && CurrentDateMedication?.length > 1 ? CurrentDateMedication?.slice(0, 5).reduce((acc, value) => acc?.totalPrice + value?.totalPrice) : CurrentDateMedication && CurrentDateMedication.length > 0 ? CurrentDateMedication[0]?.totalPrice : 0}`}</p>
                    </div>
                  </div>
                </div>
                <Card className="border border-1 p-2 mt-3">
                  <Card.Header className="bg-primary text-center text-light">
                    Latest Medication Request
                  </Card.Header>
                  <Card.Body className="px-0 py-0">
                    <table className="table table-bordered">
                      <tbody>
                        <tr>
                          <td>Pat. Name</td>
                          <td>Med. Name</td>
                          <td>Quantity</td>
                          <td>Status</td>
                        </tr>
                        {MedicationRequest &&
                          MedicationRequest.map((depts, index) => (
                            <tr key={index}>
                              <td>{depts?.patient?.fullname}</td>
                              <td>{depts?.product?.name}</td>
                              <td>{depts?.product?.stockReserve}</td>
                              <td className="text-capitalize">
                                {depts?.status}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    <div className="px-3 text-end py-2">
                      <Button
                        onClick={() => history.push("/hospital/medications")}
                      >
                        View all
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
              </Card>
            </Col>
          ) : null
        }
      </Row>

      <Row className="mt-2">
        {
          //   userType === "Receptionist" ||
          // userType?.includes("Doctor") ||
          // userType?.includes("Nurse")
          CheckPermission("Appointment Corner") ? (
            <Col xs={6}>
              <Card className="py-3">
                <div className="d-flex justify-content-between px-3">
                  <p className="text-primary p-0 m-0">{`Today ${new Date().toDateString()}`}</p>
                  <p
                    className="text-primary p-0 m-0"
                    style={{ textDecoration: "underline" }}
                  >
                    Upcoming Appointments
                  </p>
                </div>
                <Card.Body className="d-flex justify-content-between px-3">
                  <div
                    className="d-flex flex-column"
                    style={{ height: "20rem" }}
                  >
                    <AppointmentCard
                      {...{
                        name: "Scheduled",
                        count: FilteredAppointment.scheduled?.length || 0,
                        bgColor: "#395362",
                      }}
                    />
                    <AppointmentCard
                      {...{
                        name: "Inprogress",
                        count: FilteredAppointment.inProgress?.length || 0,
                        bgColor: "#EBB376",
                      }}
                    />
                    <AppointmentCard
                      {...{
                        name: "Completed",
                        count: FilteredAppointment.completed?.length || 0,

                        bgColor: "#08591F",
                      }}
                    />
                  </div>
                  <div className="d-flex flex-column gap-3">
                    <Card className="border border-0">
                      <Card.Body
                        className="px-0 px-3 border d-flex 
                                    align-items-center justify-content-center flex-column border-0 py-0"
                        style={{ minHeight: "25em" }}
                      >
                        {FilteredAppointment.currentDateAppointment &&
                        FilteredAppointment.currentDateAppointment.length ===
                          0 ? (
                          <div className="text-center">
                            <p>No Upcoming Appointments</p>
                            <Link to={"/hospital/appointments"}>
                              Go to Calendar
                            </Link>
                          </div>
                        ) : (
                          <>
                            <table
                              className="table table-bordered"
                              style={{ minHeight: "16em" }}
                            >
                              <tbody>
                                {FilteredAppointment.currentDateAppointment &&
                                  FilteredAppointment.currentDateAppointment.map(
                                    (appt, index) => (
                                      <tr key={index} className="text-primary">
                                        <td>
                                          {appt?.internalPatient?.fullname}
                                        </td>
                                        <td>
                                          {moment
                                            .utc(appt?.appointmentDate)
                                            .format("hh:mm a")}
                                        </td>
                                      </tr>
                                    ),
                                  )}
                              </tbody>
                            </table>
                            <div className="w-100 text-end py-2">
                              <Button
                                onClick={() =>
                                  history.push("/hospital/appointments")
                                }
                              >
                                View Appointments
                              </Button>
                            </div>
                          </>
                        )}
                      </Card.Body>
                    </Card>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ) : null
        }
      </Row>

      <Row className="d-flex justify-content-center">
        {
          // userType === "Inventory Manager"
          CheckPermission("Product Restock Corner") ? (
            <Col xs={9}>
              <Card className="border border-1 p-2">
                <Card.Header className="bg-primary text-center text-light">
                  Products with low stock level
                </Card.Header>
                <Card.Body className="px-0 py-0">
                  <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <td>SKU</td>
                        <td>Medication Name</td>
                        <td>Stock Level</td>
                        <td>Status</td>
                      </tr>
                      {InstockAndOutOfStockProducts &&
                        InstockAndOutOfStockProducts.map((depts, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{depts.name}</td>
                            <td className="d-flex">
                              {depts?.stock?.quantity}
                              {
                                <Progress.Line
                                  showInfo={false}
                                  percent={
                                    depts?.stockLevel?.stockLevelPercent == 0
                                      ? 2
                                      : depts?.stockLevel?.stockLevelPercent
                                  }
                                  strokeColor={
                                    depts?.stockLevel?.stockLevelColor
                                  }
                                />
                              }
                            </td>
                            <td>
                              {depts.stock?.quantity <= depts.stockReserve
                                ? `Out-Of-Stock`
                                : `In-Stock`}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  <div className="px-3 text-end py-2">
                    <Button onClick={() => history.push("/hospital/inventory")}>
                      View All Product
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ) : null
        }
      </Row>
    </React.Fragment>
  );
};

export default NewDashboardUserView;
