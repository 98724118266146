import { Row } from "react-bootstrap";
import { IInvoicePayload } from "../../../interfaces/invoice";

interface IBilledInvoiceCard {
  invoiceInfo: IInvoicePayload;
  currency: string;
}

const BoxCard: React.FC<any> = ({ data }) => (
  <Row
    className=" px-3 py-3 mx-1 d-flex text-primary shadow border rounded-3"
    style={{ maxWidth: "11rem", minWidth: "9rem", backgroundColor: "#fff" }}
  >
    <p className="mb-2">{data?.title}</p>
    <p className="fw-bold m-0" style={{ fontSize: "0.9rem" }}>
      {data?.count}
    </p>
  </Row>
);

const BilledInvoiceCard: React.FC<IBilledInvoiceCard> = (props) => {
  return (
    <div className="d-flex justify-content-between">
      <BoxCard
        data={{
          title: `Draft invoices: ${props.invoiceInfo?.invoiceSummary.openInvoiceSummary.numberOfInvoices ? props.invoiceInfo?.invoiceSummary.openInvoiceSummary.numberOfInvoices : "0"}`,
          count: `${props.currency || ""} ${props.invoiceInfo?.invoiceSummary.openInvoiceSummary.totalAmount || 0}`,
        }}
      />
      <BoxCard
        data={{
          title: `Unpaid invoices: ${props.invoiceInfo?.invoiceSummary.unpaidInvoiceSummary.numberOfInvoices ? props.invoiceInfo?.invoiceSummary.unpaidInvoiceSummary.numberOfInvoices : "0"}`,
          count: `${props.currency ? props.currency : ""} ${props.invoiceInfo?.invoiceSummary.unpaidInvoiceSummary.totalAmount ? props.invoiceInfo?.invoiceSummary.unpaidInvoiceSummary.totalAmount : "0"}`,
        }}
      />
      <BoxCard
        data={{
          title: `Paid invoices: ${props.invoiceInfo?.invoiceSummary.paidInvoiceSummary.numberOfInvoices ? props.invoiceInfo?.invoiceSummary.paidInvoiceSummary.numberOfInvoices : "0"}`,
          count: `${props.currency ? props.currency : ""} ${props.invoiceInfo?.invoiceSummary.paidInvoiceSummary.totalAmount ? props.invoiceInfo?.invoiceSummary.paidInvoiceSummary.totalAmount : "0"}`,
        }}
      />
    </div>
  );
};

export default BilledInvoiceCard;
