export interface Guide {
  name: string;
  path?: string;
  icon?: string;
}

const Guides: Array<Guide> = [
  {
    name: "Dashboard",
    path: "dashboard",
    icon: "bi bi-grid",
  },
  {
    name: "Appointments",
    path: "appointments",
    icon: "bi bi-calendar3",
  },
  {
    name: "Medication",
    path: "medications",
    icon: "fas fa-capsules",
  },
  {
    name: "Labs",
    path: "labs",
    icon: "fas fa-microscope",
  },
  {
    name: "Patient Blood Management",
    path: "blood-management",
    icon: "fas fa-microscope",
  },
  {
    name: "Billing and Collection",
    path: "billing",
    icon: "fas fa-money-bill",
  },
  {
    name: "My Profile",
    path: "profile",
    icon: "fas fa-user",
  },
];

export default Guides;
