import PocketBase from 'pocketbase';
import moment from 'moment';

const pb = new PocketBase('https://pocketbase.flexipgroup.com');

// fetch a paginated records list

export const fetchNotif = async () => {
    const resultList = await pb.collection('notification').getList(1, 50, {
        filter: 'created >= "2023-10-10"',
    });
    return (resultList)
}

export const fetchUnreadNotif = async () => {
    const resultList = await pb.collection('notification').getList(1, 50, {
        filter: 'created >= "2023-10-10"&&readStatus=false',
    });
    return (resultList)
}

export const fetchNotifUnder30Days = async () => {
    // Create a new Date object with the current date.
    const today = new Date();
    // Subtract 30 days from the current date.
    today.setDate(today.getDate() - 30);


    // Console the result in localDateString format.
    console.log(today.toLocaleDateString());

    const resultList = await pb.collection('notification').getList(1, 50, {
        filter: `created >="${moment.utc(today).format('yy-mm-dd')}"&&readStatus=false`,
    });
    return (resultList)
}

export const readNotif = async (note) => {
    const update = {
        ...note,
        "readStatus": true
    };

    const record = await pb.collection('notification').update(note.id, update);
    return (record);
}

export const readAllNotif = async (records) => {

    const createRequests = [];
    for (const record of records) {
        const update = {
            ...record,
            "readStatus": true
        };

        //   createRequests.push(sdk.records.update('notification', record));
        createRequests.push(pb.collection('notification').update(record.id, update));

    }
    // Execute all the create requests in parallel

    const response = await Promise.all(createRequests);
    return response;
}

export const unreadAllNotif = async (records) => {

    const createRequests = [];
    for (const record of records) {
        const update = {
            ...record,
            "readStatus": false
        };

        //   createRequests.push(sdk.records.update('notification', record));
        createRequests.push(pb.collection('notification').update(record.id, update));

    }
    // Execute all the create requests in parallel

    const response = await Promise.all(createRequests);
    return response;
}

export const createNotificationListener = async () => {
    const resp = await pb.collection('notification').subscribe('*', (snapshot) => {
        const users = snapshot.record;
        return users;
    });

    console.log(resp, 'hhhhhh')
}